export const PageSubHeader = {
    Home: 'FAULT INQUIRY',
    RegisterFault: 'REGISTER FAULT',
    Admin: 'TABLE MAINTENANCE',
    FleetVehicles: 'MAINTAIN VEHICLES',
    Depots: 'MAINTAIN DEPOT TABLE',
    RepairAgents: 'MAINTAIN PRIMARY REPAIR AGENTS',
    SecondarySubRepairer: 'MAINTAIN SECONDARY(SUB) REPAIRER/SUPPLIER',
    ApplicationUsers: 'MAINTAIN USER DETAIL',
    FunctionGroupAllocation: 'FUNCTION GROUP ALLOCATION',
    FunctionGroups: 'MAINTAIN FUNCTION GROUPS',
    AddFleetVehicles: 'ADD VEHICLE',
    AddDepots: 'REGISTER NEW DEPOT',
    AddRepairAgents: 'REGISTER REPAIR AGENT',
    AddSecondarySubRepairer: 'REGISTER SUB REPAIR AGENT(SUBCONTRACTOR/SUB SUPPLIER)',
    AddApplicationUsers: 'REGISTER NEW USER',
    UpdateFleetVehicles: 'UPDATE VEHICLE',
    UpdateDepots: 'UPDATE DEPOT',
    UpdateRepairAgents: 'UPDATE REPAIR AGENT',
    UpdateSecondarySubRepairer: 'UPDATE SECONDARY SUB REPAIR AGENT',
    UpdateApplicationUser: 'UPDATE USER',
    UpdateFaultCase: 'FAULT CASE UPDATE'
}

export const AppRoutes = {
    Login: '/',
    Home: '/home',
    RegisterFault: '/register-fault',
    ExportData: '/export-data',
    PrintData: '/print-data',
    VehicleHistory: '/vehicle-history',
    Admin: '/admin',
    FleetVehicles: '/admin/fleet-vehicle',
    Depots: '/admin/depot',
    RepairAgents: '/admin/repair-agent',
    SecondarySubRepairer: '/admin/secondary-repair-agent',
    ApplicationUsers: '/admin/application-user',
    FunctionGroupAllocation: '/admin/function-group-allocation',
    FunctionGroups: '/admin/function-group',
    AddFleetVehicles: '/admin/fleet-vehicle/add-vehicle',
    AddDepots: '/admin/depot/add-depot',
    AddRepairAgents: '/admin/repair-agent/add-repair-agent',
    AddSecondarySubRepairer: '/admin/secondary-repair-agent/add-secondary-repair-agent',
    AddApplicationUsers: '/admin/application-user/add-application-user',
    UpdateFleetVehicles: '/admin/fleet-vehicle/update-vehicle/:id',
    UpdateDepots: '/admin/depot/update-depot/:id',
    UpdateRepairAgents: '/admin/repair-agent/update-repair-agent/:id',
    UpdateSecondarySubRepairer: '/admin/secondary-repair-agent/update-secondary-repair-agent/:id',
    UpdateApplicationUser: '/admin/application-user/update-application-user/:id',
    UpdateFaultCase: '/case-update/:id',
}