import React, { useEffect, useState } from 'react';
import { StyledMenu, StyledButton, FormItem } from '../../maintenancetable.style';
import { Row, Col, Form, Input, Space, message, Select, Switch, Menu } from 'antd';
import { Link, useHistory, useParams } from 'react-router-dom';
import { AppRoutes } from '../../../../lib/constants';
import { useTranslation } from 'react-i18next';

// api calling functions
import { addVehicleDepot, getVehicleDepotDataById, editVehicleDepot, getClientId } from '../../../../api/depots';
import { getStateList } from '../../../../api/states';
import { getVehicleOperators } from '../../../../api/vehicleoperator';
import { allUserList } from '../../../../api/users';
import { preventNonNumericalInput } from '../../../../utils';

const AddDepots = () => {
  const history = useHistory();
  const param = useParams();
  const id = param.id;
  const { t } = useTranslation();

  // using antd form hook
  const [form] = Form.useForm();

  const [vehicleoperators, setvehicleoperators] = useState([]);
  const [statelist, setstatelist] = useState([]);
  const [clientId, setclientId] = useState([]);
  const [personList, setPersonList] = useState([]);
  
  const [initialValue, setinitialValue] = useState([]);
  const [numberReq, setnumberReq] = useState(false);
  const [emailReq, setemailReq] = useState(false);

  useEffect(() => {
    getVehicleOperators().then((response) => {
      let operators = [];
      response.data.map((data) => (
        operators.push(data.operatorName)
      ))
      setvehicleoperators(operators);
    });

    getStateList().then((response) => {
      setstatelist(response.data);
    });

    getClientId().then((response) => {
      setclientId(response.data);
    });

    allUserList().then((response) => {
      setPersonList(response.data);
    });

    id && getVehicleDepotDataById(id).then(response => {
      setinitialValue({
        'ClientId': response.data.clientId,
        'DepotName': response.data.depotName,
        'OperatorName': response.data.depotOperatorName,
        'DepotEmailAddress': response.data.depotEmailAddress,
        'DepotPhoneNumber': response.data.depotSMSPhoneNumber,
        'DepotNotifyEmail': response.data.depotAgentNotifyByEmail,
        'DepotNotifySMS': response.data.depotAgentNotifyBySms,
        'ContactPersonId': response.data.depotContactPersonId,
        'DepotAddress1': response.data.depotAddress01,
        'DepotAddress2': response.data.depotAddress02,
        'DepotTown': response.data.depotTownSuburb,
        'State': response.data.depotState,
        'Postcode': response.data.depotPostCode
      })
    })
  }, [id])

  useEffect(() => {
    form.setFieldsValue(initialValue);
    setemailReq(initialValue?.DepotNotifyEmail);
    setnumberReq(initialValue?.DepotNotifySMS);
  }, [initialValue, form])


  const onFinish = (value) => {

    let datatosend = {
      id: id,
      'clientId': value['ClientId'] ?? '',
      'depotName': value['DepotName']?.trim() ?? '',
      'depotOperatorName': value['OperatorName'] ?? '',
      'depotEmailAddress': value['DepotEmailAddress'] ?? '',
      'depotSMSPhoneNumber': value['DepotPhoneNumber'] ?? '',
      'depotAgentNotifyByEmail': value['DepotNotifyEmail'] ?? false,
      'depotAgentNotifyBySms': value['DepotNotifySMS'] ?? false,
      'depotContactPersonId': value['ContactPersonId'] ?? '',
      'depotAddress01': value['DepotAddress1']?.trim() ?? '',
      'depotAddress02': value['DepotAddress2']?.trim() ?? '',
      'depotTownSuburb': value['DepotTown'] ?? '',
      'depotState': value['State'] ?? '',
      'depotPostCode': value['Postcode'] ?? ''
    }

    if (id) {
      editVehicleDepot(datatosend).then((response) => {
        message.success(t('message.editdepotsuccess'));
        form.resetFields();
        history.push(AppRoutes.Depots);
      }).catch((errr) => {
        message.error(errr?.response?.data);
      });
    } else {
      addVehicleDepot(datatosend).then((response) => {
        message.success(t('message.depotaddsuccess'));
        form.resetFields();
        history.push(AppRoutes.Depots);
      }).catch((errr) => {
        message.error(errr?.response?.data);
      });
      
    }
  };

  const onFinishFailed = () => {
    message.error('Submit failed!')
  };

  const onSmsSwitchChange = (Ans) => {
    setnumberReq(Ans);
  }

  const onEmailSwitchChange = (Ans) => {
    setemailReq(Ans);
  }


  return (
    <>
      <StyledMenu mode="horizontal" style={{ marginBottom: '10px' }}>
        <Menu.Item key={t('menu.home')}>
          <Link to={AppRoutes.Home}>{t('menu.home')}</Link>
        </Menu.Item>
      </StyledMenu>

      <div style={{ margin: 20 }}>
        <Row >
          <Col xs={24} xl={16} sm={16} lg={16}>
            <Form form={form} labelCol={{ span: 12, offset: 1 }} wrapperCol={{ span: 10, }} layout="horizontal" initialValues={{ size: 'default', }} onFinish={onFinish}
              onFinishFailed={onFinishFailed}>
              <FormItem name="ClientId" label="CLIENT ID" rules={[{ required: true, message: "'Client Id' is Required" }]}>
                <Select placeholder='Please select'>
                  {clientId?.map(client => {
                    return <Select.Option key={client} value={client}>{client}</Select.Option>
                  })}
                </Select>
              </FormItem>

              <FormItem name="OperatorName" label="OPERATOR NAME" rules={[{ required: true, message: "'Operator Name' is Required" }]}>
                <Select placeholder='Please select'>
                  {vehicleoperators && vehicleoperators.map(lists => {
                    return <Select.Option key={lists} value={lists}>{lists}</Select.Option>
                  })}
                </Select>
              </FormItem>

              <FormItem name="DepotName" label="DEPOT NAME" rules={[{ required: true, message: "'Depot Name' is Required" }, { max: 50, message: "'Depot Name' cannot be longer than 50 characters" }]}>
                <Input placeholder="Enter Depot Name" />
              </FormItem>

              <FormItem name="DepotAddress1" label="DEPOT ADDRESS" rules={[{ max: 50, message: "'Depot Address' cannot be longer than 50 characters" }]}>
                <Input placeholder="Enter Depot Address" />
              </FormItem>

              <FormItem label=" " colon={false} name="DepotAddress2" rules={[{ max: 50, message: "'Depot Address 2' cannot be longer than 50 characters" }]}>
                <Input placeholder="Enter Depot Address 2" />
              </FormItem>

              <FormItem name="DepotTown" label="DEPOT TOWN" rules={[{ max: 50, message: "'Depot Town' cannot be longer than 50 characters" }]}>
                <Input placeholder="Enter Depot Town" />
              </FormItem>

              <FormItem name="State" label="STATE" rules={[{ required: true, message: "'State' is Required" }]}>
                <Select placeholder='Please select'>
                  {statelist && statelist.map(lists => {
                    return <Select.Option key={lists.stateCode} value={lists.stateName}>{lists.stateName}</Select.Option>
                  })}
                </Select>
              </FormItem>

              <FormItem name="Postcode" label="POSTCODE" rules={[{ max: 7, message: "'Postcode' cannot be longer than 7 characters" }]}>
                <Input type="number" placeholder="Enter Postcode" />
              </FormItem>

              <FormItem name="DepotNotifyEmail" label="Depot Notify by Email " valuePropName="checked">
                <Switch checkedChildren="Yes" unCheckedChildren="No" onChange={onEmailSwitchChange}></Switch>
              </FormItem>

              <FormItem name="DepotNotifySMS" label="Depot Notify by SMS " valuePropName="checked">
                <Switch checkedChildren="Yes" unCheckedChildren="No" onChange={onSmsSwitchChange}></Switch>
              </FormItem>

              <FormItem name="DepotEmailAddress" label="Depot Email Address" rules={[emailReq ? { max: 100, type: 'email', required: true }:{max: 100, type: 'email',}]}>
                <Input placeholder="Enter Email Address" />
              </FormItem>

              <FormItem name="DepotPhoneNumber" label="Depot Phone Number " rules={numberReq ? [{ pattern: /^[+;](61\d{9})/ , message:t('validation.phoneerror')}, {required: true , message:'Depot Phone Number Is Required'}]:[{ pattern: /^[+;](61\d{9})/ , message:t('validation.phoneerror')}]}>
                <Input placeholder="Enter Depot Phone Number" onInput={(e) => { if (e.currentTarget.value.slice(0, 3) === "+61") e.currentTarget.value = e.currentTarget.value.slice(0, 12) }} onKeyPress={(e) => preventNonNumericalInput(e)}/>
              </FormItem>

              <FormItem name="ContactPersonId" label="CONTACT PERSON ID" rules={[{ max: 10 }]}>
                <Select placeholder='Please select'>
                  {personList?.map(person => {
                    return <Select.Option key={person.id} value={person.userId}>{person.userId}</Select.Option>
                  })}
                </Select>
              </FormItem>

              <Space style={{ display: 'flex', justifyContent: 'center', paddingTop: 30 }}>
                <FormItem>
                  <StyledButton htmlType="submit">{t('lblsubmit')}</StyledButton>
                </FormItem>
                <FormItem>
                  <StyledButton><Link to={AppRoutes.Depots}>{t('lblcancel')}</Link></StyledButton>
                </FormItem>
              </Space>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default AddDepots;