import { request } from '../lib/request';

const secondarySubRepairerApi = request({
    baseURL: process.env.REACT_APP_BASE_API + 'secondary-vehicle-repair-agent'
});

export const getSecondarySubRepairerData = async () => {
    return await secondarySubRepairerApi.get('/list');
}
export const deleteSecondarySubRepairer = async (id) => {
  return await secondarySubRepairerApi.delete(`/${id}`);
}

export const addSecondarySubRepairAgent = async (payload) => {
  return await secondarySubRepairerApi.post('', payload);
}

export const getSecondarySubRepairAgentById = async (id) => {
  return await secondarySubRepairerApi.get(`/${id}`);
}

export const updateSecondarySubRepairAgent = async (payload) => {
  return await secondarySubRepairerApi.put('', payload);
}