import React , { useState , useEffect } from 'react';
import { StyledMenu , StyledTable } from '../../maintenancetable.style';
import { useTranslation } from 'react-i18next';
import { Menu , Space , message , Modal, Button } from 'antd';
import { Link , useHistory} from 'react-router-dom';
import { AppRoutes } from '../../../../lib/constants';

import { getDepotsData , deleteDepotsRecord } from '../../../../api/depots';


import { ExclamationCircleOutlined } from '@ant-design/icons';
const { confirm } = Modal;


const Depots = () => {
  const history = useHistory(); 
  const { t } = useTranslation();

  const [source, setSource] = useState([]);
  
  // for updating source
  useEffect(() => {
    getDepotsData().then(response => {
      setSource(response?.data);
    })
      .catch(error => {
        alert(error);
      })
  }, []);

  // for deleting depots
  const deleteDepots = (id) => {
    if(id) {
      confirm({
        title: t('confirm.deletedepot'),
        icon: <ExclamationCircleOutlined />,
        onOk() {
          deleteDepotsRecord(id).then((response) => {
            if(response.status === 200) {
              let filteredData = source.filter(datas => datas.id.toString() !== id.toString());
              setSource(filteredData);        
              message.success(t('message.depotdeletedsuccss'))
            } else {
              message.error(t('message.depotdeleteerror'));
            }
          }).catch((err) => {
            message.error(t('message.depotdeleteerror'));
          })
        },
      });
    }
  }


  const columns = [
    {
      title: 'Client Id',
      dataIndex: 'clientId',
      key: 'clientId',
      width:'10%'
    },
    {
      title: 'Operator',
      dataIndex:'depotOperatorName',
      key:'depotOperatorName',
      width:'13%'
    },
    {
      title: 'Depot Name',
      dataIndex:'depotName',
      key:'depotName',
      width:'10%',
    },
    {
      title: 'Notify by Email',
      dataIndex:'depotAgentNotifyByEmail',
      key:'depotAgentNotifyByEmail',
      width:'9%',
    },
    {
      title: 'Notify by SMS',
      dataIndex:'depotAgentNotifyBySms',
      key:'depotAgentNotifyBySms',
      width:'9%',
    },
    {
      title: 'Email Address',
      dataIndex:'depotEmailAddress',
      key:'depotEmailAddress',
      width:'17%',
    },
    {
      title: 'SMS Phone No',
      dataIndex:'depotSMSPhoneNumber',
      key:'depotSMSPhoneNumber',
      width:'12%',
    },
    {
      title: 'Contact Person ID',
      dataIndex:'depotContactPersonId',
      key:'depotContactPersonId',
      width:'12%',
    },
    {
      title: 'Action',
      key:'action',
      width:'15%',
      render:(text,record) => ( 
      <> 
       <Space size="middle">
        <Button type="link" size="small" onClick={() => history.push(AppRoutes.UpdateDepots.replace(':id', record.key))}>{t('lbledit')}</Button>
        <Button type="link" size="small" onClick={() => {  deleteDepots(record.key)}}>{t('lbldelete')}</Button>
       </Space> 
      </> )

    },

  ];

  const dataSource = source?.map((data) => {
    return {
      key: data?.id.toString(),
      clientId: data?.clientId,
      depotOperatorName: data?.depotOperatorName,
      depotName: data?.depotName,
      depotAgentNotifyByEmail: data?.depotAgentNotifyByEmail ? "Yes" : "No",
      depotAgentNotifyBySms: data?.depotAgentNotifyBySms ? "Yes" : "No",
      depotEmailAddress: data?.depotEmailAddress,
      depotSMSPhoneNumber: data?.depotSMSPhoneNumber,
      depotContactPersonId: data?.depotContactPersonId,
      action: data?.clientId
    }
  });

  return (
    <>
    <StyledMenu mode="horizontal">
      <Menu.Item key={t('menu.home')}>
        <Link to={AppRoutes.Home}>{t('menu.home')}</Link>
      </Menu.Item>
      <Menu.Item key={t('menu.adddepots')}>
        <Link to={AppRoutes.AddDepots}>{t('menu.adddepots')}</Link>
      </Menu.Item>
    </StyledMenu>

     <div style={{ paddingTop: '1.25rem' }}>
        <StyledTable
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          bordered
        />
      </div>
    </>
  );
}

export default Depots;
