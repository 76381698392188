const palette = {  
  grey: {
    /**
     * #F7F8FC
     */
    0: '#F7F8FC',
    /**
     * #EDEDF4
     */
    1: '#EDEDF4',
    /**
     * #E1E1EA
     */
    2: '#E1E1EA',
    /**
     * #D4D5DD
     */
    3: '#D4D5DD',
    /**
     * #BCBCC9
     */
    4: '#BCBCC9',
    /**
     * #9699AA
     */
    5: '#9699AA',
    /**
     * #7C7E8D
     */
    6: '#7C7E8D',
    /**
     * #676978
     */
    7: '#676978',
    /**
     * #484A58
     */
    8: '#484A58',
    /**
     * #363842
     */
    9: '#363842',
  }, 
  red: {
    /**
     * #FFF2F1
     */
    0: '#FFF2F1',
    /**
     * #D95852
     */
    1: '#D95852',
    /**
     * #E89B97
     */
    2: '#E89B97',
    /**
     * #FF6C65
     */
    3: '#FF6C65',
  },   
  white: {
    /**
     * #FFFFFF
     */
    0: '#FFFFFF',
  },
  black: {
    /**
     * #000000
     */
    0: '#000000',
  },
};

const sizes = {
  initial: 'initial',
  inherit: 'inherit',
  auto: 'auto',  
  0: '0',
  1: '0.063rem',
  2: '0.125rem',
  5: '0.313rem',
  6: '0.375rem',
  8: '0.5rem',
  10: '0.625rem',
  14: '0.875rem',
  16: '1rem',
  28: '1.75rem',
  30: '1.875rem',
  35: '2.188rem',  
  40: '2.5rem',
  41: '2.563rem',
  50: '3.125rem',
  70: '4.375rem',
  80: '5rem',
  106: '6.625rem',
  180: '11.25rem', 
  200: '12.5rem',
  400: '25rem',
  700: '43.75rem',
  /**
   * 100%
   */
  full: '100%',
  /**
   * 50%
   */
  half: '50%',
  /**
   * 224px
   */
  '3xs': '14rem',
  /**
   * 256px
   */
  '2xs': '16rem',
  /**
   * 320px
   */
  xs: '20rem',
  /**
   * 384px
   */
  sm: '24rem',
  /**
   * 444px
   */
  '1md': '27.75rem',
  /**
   * 448px
   */
  md: '28rem',
  /**
   * 512px
   */
  lg: '32rem',
  /**
   * 576px
   */
  xl: '36rem',
  /**
   * 672px
   */
  '2xl': '42rem',
  /**
   * 768px
   */
  '3xl': '48rem',
  /**
   * 896px
   */
  '4xl': '56rem',
  /**
   * 1024px
   */
  '5xl': '64rem',
  /**
   * 1152px
   */
  '6xl': '72rem',
  /**
   * 1440px
   */
  '7xl': '90rem',
};

const fontWeights = {
  /**
   * Normal - 400
   */
  normal: 400,
  /**
   * Bold - 600
   */
  bold: 600,
  /**
   * Black - 700
   */
  black: 700,
};

const breakpoints = {
  /**
   * 375px
   */
  375: '23.438rem',
  /**
   * 480px
   */
  480: '30rem',
  /**
   * 768px
   *
   * The exact transition point from mobile to tablet.
   */
  768: '48rem',
  /**
   * 992px
   */
  992: '62rem',
  /**
   * 1024px
   *
   * The exact transition point from tablet to desktop.
   */
  1024: '64rem',
  /**
   * 1280px
   */
  1280: '80rem',
  /**
   * 1440px
   */
  1440: '90rem',
  /**
   * 1680px
   */
  1680: '105rem',
  /**
   * 1920px
   */
  1920: '120rem',
  /**
   * 2560px
   */
  2560: '160rem',
};

export const tokens = { 
  sizes,  
  palette,
  fontWeights, 
  breakpoints, 
};
