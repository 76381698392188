
import React, { useEffect, useRef, useState } from 'react';
import { StyledMenu, StyledButton, FormItem } from '../../maintenancetable.style';
import { Row, Col, Form, Input, Space, message, Select, Switch, Menu } from 'antd';
import { Link, useHistory, useParams } from 'react-router-dom';
import { AppRoutes } from '../../../../lib/constants';
import { useTranslation } from 'react-i18next';

import { getUserTypes, getSmsTimings, addUser, updateUserDetail, getUserDetail } from '../../../../api/users';
import { getSecondarySubRepairerData } from '../../../../api/secondarySubRepairer';
import { getVehicleOperators } from '../../../../api/vehicleoperator';
import { getDepotsData } from '../../../../api/depots';
import moment from 'moment';
import { preventNonNumericalInput } from '../../../../utils';


const AddApplicationUsers = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const param = useParams();
  const id = param.id;

  // using antd form hook
  const [form] = Form.useForm();
  const [usertype, setusertype] = useState([]);
  const [smstiming, setsmstiming] = useState([]);
  const [repairAgent, setrepairAgent] = useState([]);
  const [primaryRepairAgent, setPrimaryRepairAgent] = useState([]);
  const [vehicleoperator, setvehicleoperator] = useState([]);
  const [depotdata, setdepotdata] = useState([]);
  const [filteredDepotdata, setfilteredDepotdata] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState([]);

  const [numberReq, setnumberReq] = useState(false);
  const [emailReq, setemailReq] = useState(false);
  const [initialValue, setinitialValue] = useState([]);

  const [isDepotNameVisible, setIsDepotNameVisible] = useState(true);
  const [isSubContractorReq, setIsSubContractorReq] = useState(true);
  const [isRepairAgentReq, setIsRepairAgentReq] = useState(true);
  const [isOperatorReq, setIsOperatorReq] = useState(true);
  const [isShowDepotNameInput, setIsShowDepotNameInput] = useState(false);
  const [isDepotNameRequired, setIsDepotNameRequired] = useState(false);
  const [isAMuserType,setisAMuserType] = useState(false);
  let depotClone = useRef(null);

  useEffect(() => {

    // to set usertype
    getUserTypes().then((res) => {
      setusertype(res.data);
    })

    getDepotsData().then((res) => {
      setdepotdata(res.data);
      if (selectedOperator !== undefined) {
        setfilteredDepotdata(res.data?.filter(depot => depot.depotOperatorName === selectedOperator));
      } else {
        setfilteredDepotdata(res.data);
      }
      depotClone.current = res?.data;
    })

    getSecondarySubRepairerData().then((agents) => {
      setrepairAgent(agents.data);
      let from = Array.from(agents.data, (agent => {
        return agent.primaryRepairAgent
      }));
      setPrimaryRepairAgent(from);
    })

    getVehicleOperators().then((operator) => {
      setvehicleoperator(operator.data);
    })

    getSmsTimings().then((timings) => {
      setsmstiming(timings.data);
    })

    id && getUserDetail(id).then((response) => {
      setinitialValue({
        'UserId': response?.data?.userId,
        'UserName': response?.data?.userName,
        'UserType': response?.data?.levelType,
        'DepotName': response?.data?.depotName,
        'RepairAgentSubContractor': response?.data?.supplierRepairAgentName,
        'Operator': response?.data?.operatorName,
        'UserNotifybyEmail': response?.data?.notifyByEmail,
        'UserEmailAddress': response?.data?.emailAddress,
        'UserNotifyBySMS': response?.data?.notifyBySms,
        'UserSMSPhone': response?.data?.smsPhoneNumber,
        'TimeFrom': response?.data?.smsAvlHourFrom,
        'TimeTo': response?.data?.smsAvlHourTo
      });
      onUserTypeChange(response?.data?.levelType);
      onOperatorChange(response?.data?.operatorName, depotClone.current);

      //put this line only after onUserTypeChange() else will not prepop data
      if(response?.data?.levelType === 'AM') {
        let tags = response?.data?.depotName;
        tags = tags.split(',');
        form.setFieldsValue({DepotName:tags});
      } else {
        form.setFieldsValue({DepotName:response?.data?.depotName});
      }
    }).catch((err) => {
      console.log(err)
    })
// eslint-disable-next-line
  }, [id,form])

  useEffect(() => {
    form.setFieldsValue(initialValue);
    setemailReq(initialValue?.UserNotifybyEmail);
    setnumberReq(initialValue?.UserNotifyBySMS);
  }, [initialValue, form])

  const SubmitForm = (value) => {
    let from = value['TimeFrom'];
    let to = value['TimeTo'];

    if (from !== undefined && to !== undefined) {
      from = moment(from, "h:m");
      to = moment(to, "h:m");

      if (from.isSameOrAfter(to)) {
        message.error(t('message.fromishigher'));
        return;
      }
    }
    let depotResult = '';
    if(Array.isArray(value['DepotName'])) {
      depotResult = value['DepotName'].toString();
    } else {
      depotResult = value['DepotName'];
    }

    let datatosend = {
      id: id,
      'userId': value['UserId']?.trim() ?? '',
      'userName': value['UserName']?.trim() ?? '',
      'levelType': value['UserType'] ?? '',
      'depotName': depotResult ?? '',
      'supplierRepairAgentName': value['RepairAgentSubContractor'] ?? value['RepairAgentPrimary'] ?? '',
      'operatorName': value['Operator'] ?? '',
      'notifyByEmail': value['UserNotifybyEmail'] ?? false,
      'emailAddress': value['UserEmailAddress'] ?? '',
      'notifyBySms': value['UserNotifyBySMS'] ?? false,
      'smsPhoneNumber': value['UserSMSPhone'] ?? '',
      'smsAvlHourFrom': value['TimeFrom'] ?? '',
      'smsAvlHourTo': value['TimeTo'] ?? ''
    }

    if (id) {
      updateUserDetail(datatosend).then(responce => {
        message.success(t('message.updateusersuccess'));
        form.resetFields();
        history.push(AppRoutes.ApplicationUsers);
      }).catch(error => {
        message.error(error?.response?.data ? error?.response?.data : t('message.updateusererror'));
      })
    } else {
      addUser(datatosend).then((responce) => {
        message.success(t('message.addusersuccess'));
        form.resetFields();
        history.push(AppRoutes.ApplicationUsers);
      }).catch((error) => {
        message.error(error?.response?.data ? error?.response?.data : t('message.addusererror'));
      })
    }

  }

  const onSmsSwitchChange = (Ans) => {
    setnumberReq(Ans);
  }

  const onEmailSwitchChange = (Ans) => {
    setemailReq(Ans);
  }

  const onOperatorChange = (value, data) => {
    setSelectedOperator(value);
     form.getFieldValue('UserType') !== 'FM' && form.setFieldsValue({ DepotName: '' });
     form.getFieldValue('UserType') === 'AM' && form.setFieldsValue({DepotName:[]});
      data = data ? data : depotdata
      setfilteredDepotdata(data?.filter(depot => depot.depotOperatorName === value));
  }

  const onUserTypeChange = (value) => {

    setIsDepotNameVisible(true);
    setIsOperatorReq(true);
    setIsSubContractorReq(true);
    setIsShowDepotNameInput(false);
    setisAMuserType(false);
    setIsRepairAgentReq(false)
    form.setFieldsValue({DepotName :[]});
    form.getFieldValue('Operator') && onOperatorChange(form.getFieldValue('Operator'));
    switch(value) {
      case "DEPOT":
        setIsSubContractorReq(false);
        setIsDepotNameRequired(true);
        break;
      case "SSGR1":
        setIsDepotNameVisible(false);
        setIsOperatorReq(false);
        break;
      case "SSGR2":
        setIsDepotNameVisible(false);
        setIsOperatorReq(false);
        break;
      case "FM":
        setIsSubContractorReq(false);
        setIsDepotNameRequired(false);
        setIsShowDepotNameInput(true);
        form.setFieldsValue({ DepotName :'ALL'});
        break;
      case "AM":
        setIsSubContractorReq(false);
        setIsShowDepotNameInput(false);
        setIsDepotNameRequired(false);
        setisAMuserType(true);
        //form.setFieldsValue({ DepotName :'ALL'});
        break;
      case "PSGRP":
        setIsDepotNameVisible(false);
        setIsOperatorReq(false);
        setIsSubContractorReq(false);
        setIsRepairAgentReq(true)
        break;
      case "PTAFM":
        setIsDepotNameVisible(false);
        setIsOperatorReq(false);
        setIsSubContractorReq(false);
        break;
      case "PCMP":
        setIsDepotNameVisible(false);
        setIsOperatorReq(false);
        setIsSubContractorReq(false);
        break;
      case "ADMIN":
        setIsDepotNameVisible(false);
        setIsOperatorReq(false);
        setIsSubContractorReq(false);
        break;
      default:
        return null;
    }
  }

  return (
    <>
      <StyledMenu mode="horizontal">
        <Menu.Item key={t('menu.home')}>
          <Link to={AppRoutes.Home}>{t('menu.home')}</Link>
        </Menu.Item>
      </StyledMenu>

      <div style={{ margin: 50 }}>
        <Row >
          <Col xs={24} xl={16} sm={16} lg={16}>
            <Form form={form} labelCol={{ span: 12, offset: 1 }} wrapperCol={{ span: 10, }} layout="horizontal" initialValues={{ size: 'default', }} onFinish={SubmitForm} >
              <FormItem name="UserId" label="USER ID" rules={[{ required: true, message: "'User Id' is Required" }, { max: 10, message: "'User Id' cannot be longer than 10 characters" }]}>
                <Input placeholder="Enter User Id" disabled={id ? true : false}/>
              </FormItem>

              <FormItem name="UserName" label="USER NAME" rules={[{ required: true, message: "'User Name' is Required" }, { max: 50, message: "'User Name' cannot be longer than 50 characters" }]}>
                <Input placeholder="Enter User Name" />
              </FormItem>

              <FormItem name="UserType" label="USER TYPE" rules={[{ required: true, message: "'User Type' is Required" }]}>
                <Select placeholder="Please select"  onChange={(value) => onUserTypeChange(value)}>
                  {usertype && usertype.map(lists => {
                    return <Select.Option key={lists} value={lists} >{lists}</Select.Option>
                  })}
                </Select>
              </FormItem>

              {isOperatorReq && <FormItem name="Operator" label="OPERATOR" rules={[{ required: true, message: "'Operator' is Required" }]}>
                <Select placeholder="Please select"  onChange={(value) => onOperatorChange(value)}>
                  {vehicleoperator && vehicleoperator.map(lists => {
                    return <Select.Option key={lists.operatorName} value={lists.operatorName} >{lists.operatorName}</Select.Option>
                  })}
                </Select>
              </FormItem>
              }

              {isDepotNameVisible && <FormItem name="DepotName" label="DEPOT NAME" rules={[{required: isDepotNameRequired, message: "'Depot Name' is Required"}]}>
                {
                  isShowDepotNameInput ?
                    <Input disabled/>
                    : <Select mode={isAMuserType ? "multiple" : ''} placeholder="Please select">
                      {filteredDepotdata && filteredDepotdata.map(depot => {
                        return <Select.Option key={depot.id} value={depot.depotName} >{depot.depotName}</Select.Option>
                      })}
                    </Select>
                }
              </FormItem>}


              {isSubContractorReq && <FormItem name="RepairAgentSubContractor" label="REPAIR AGENT SUB CONTRACTOR" rules={[{ required: true, message: "'Repair Agent Sub Contractor' is Required" }]}>
                <Select placeholder="Please select">
                  {repairAgent && repairAgent.map(lists => {
                    return <Select.Option key={lists.name} value={lists.name} >{lists.name}</Select.Option>
                  })}
                </Select>
              </FormItem>
              }

              {isRepairAgentReq && <FormItem name="RepairAgentPrimary" label="REPAIR AGENT" rules={[{ required: true, message: "'Repair Agent' is Required" }]}>
                <Select placeholder="Please select">
                  {primaryRepairAgent && [...new Set(primaryRepairAgent)].map(lists => {
                    return <Select.Option key={lists} value={lists} >{lists}</Select.Option>
                  })}
                </Select>
              </FormItem>
              }

              <FormItem name="UserNotifybyEmail" label="USER NOTIFY BY EMAIL" valuePropName="checked">
                <Switch checkedChildren="Yes" unCheckedChildren="No" onChange={onEmailSwitchChange}></Switch>
              </FormItem>

              <FormItem name="UserEmailAddress" label="USER EMAIL ADDRESS" rules={emailReq ? [{ max: 100, message: "'User Email Address' cannot be longer than 100 characters" }, { type: 'email', message: "Enter a Valid Email Address" }, { required: true, message: "'User Email Address' is Required" }] : [{ max: 100, message: "'User Email Address' cannot be longer than 100 characters" }, { type: 'email', message: "Enter a Valid Email Address" }]}>
                <Input placeholder="Enter Email Address" />
              </FormItem>

              <FormItem name="UserNotifyBySMS" label="USER NOTIFY BY SMS" valuePropName="checked">
                <Switch checkedChildren="Yes" unCheckedChildren="No" onChange={onSmsSwitchChange} ></Switch>
              </FormItem>

              <FormItem name="UserSMSPhone" label="USER SMS PHONE NUMBER"
                rules={numberReq ? [{ required: true, message: "'User SMS Phone Number' is Required" }, {pattern: /^[+;](61\d{9})/,message: t('validation.phoneerror')}]:[{pattern: /^[+;](61\d{9})/,message: t('validation.phoneerror')}]}>
                <Input
                  placeholder="Enter Phone Number"
                  onKeyPress={(e) => preventNonNumericalInput(e)}
                  onInput={(e) => { if (e.currentTarget.value.slice(0, 3) === "+61") e.currentTarget.value = e.currentTarget.value.slice(0, 12) }}
                />
              </FormItem>

              <FormItem name="TimeFrom" label="TIME FROM" >
                <Select placeholder="Please select">
                  {smstiming && smstiming.map(times => {
                    return <Select.Option key={times} value={times} >{times}</Select.Option>
                  })}
                </Select>
              </FormItem>

              <FormItem name="TimeTo" label="TIME TO" >
                <Select placeholder="Please select">
                  {smstiming && smstiming.map(times => {
                    return <Select.Option key={times} value={times} >{times}</Select.Option>
                  })}
                </Select>
              </FormItem>

              <Space style={{ display: 'flex', justifyContent: 'center', paddingTop: 30 }}>
                <FormItem>
                  <StyledButton htmlType="submit">{t('lblsubmit')}</StyledButton>
                </FormItem>
                <FormItem>
                  <StyledButton><Link to={AppRoutes.ApplicationUsers}>{t('lblcancel')}</Link></StyledButton>
                </FormItem>
              </Space>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default AddApplicationUsers;
