import {request} from '../lib/request';

const GET_STATE_LIST = 'list';

const statesApi = request({
  baseURL: process.env.REACT_APP_BASE_API + 'state/'
});

export const getStateList = async () => {
    return await statesApi.get(GET_STATE_LIST);
}