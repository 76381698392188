import { request, requestObj } from '../lib/request';

const depotsApi = request({
  baseURL: process.env.REACT_APP_BASE_API + 'vehicle-depot/'
});

export const getDepotsData = async () => {
  return await depotsApi.get('list');
}

export const addVehicleDepot = async (payload) => {
  return await depotsApi.post('', payload)
}

export const deleteDepotsRecord = (id) => {
  return depotsApi.delete(`/${id}`);
}

export const editVehicleDepot = async (payload) => {
  return await depotsApi.put('', payload);
}

export const getVehicleDepotDataById = (id) => {
  return depotsApi.get(`/${id}`);
}

export const getClientId = (id) => {
  return requestObj.get('client-id/list');
}