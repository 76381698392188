import styled from 'styled-components';
import { Button, Menu, Select, DatePicker, Table, Form, Modal } from 'antd';
import { tokens } from '../../lib/tokens';

export const StyledTable = styled(Table)`
    .ant-table-thead > tr > th {
        background: ${tokens.palette.grey[8]};
        color: ${tokens.palette.white[0]};
        padding-left: ${tokens.sizes[8]};
        font-size: ${tokens.sizes[14]};
    }
    .ant-table-thead > tr > th:hover {
        background: ${tokens.palette.grey[9]};
    }
    .ant-table-tbody > tr > td {
        padding: ${tokens.sizes[8]};
        font-size: ${tokens.sizes[14]};
    }
    .ant-table {
        line-height: 1.4;
    }   
    
    .ant-table-tbody > tr.ant-table-row-level-0:hover > td {
        background: unset;
    }
`;

export const StyledImage = styled.img`
    float: left;
    height: ${tokens.sizes[106]};
    padding-top: ${tokens.sizes[28]};
`;

export const StyledButton = styled(Button)`
    margin-top: ${tokens.sizes[40]};
    width: ${tokens.sizes[200]};
    background-color: ${tokens.palette.grey[1]};
    border: ${tokens.sizes[1]} solid ${tokens.palette.black[0]};
    font-weight: 600;
`;

export const StyledSelect = styled(Select)`
    width: ${tokens.sizes[180]};
    top: ${tokens.sizes[10]};
`;

export const StyledDatePicker = styled(DatePicker)`
    width: ${tokens.sizes[180]};
    top: ${tokens.sizes[10]};
`;

export const StyledMenu = styled(Menu)`
    background-color: ${tokens.palette.grey[3]};
    font-weight: 600;
`;
export const StyledHeader = styled.h3`
    margin: ${tokens.sizes[41]} 0 0 0; 
    background-color: #D4D5DD;
    text-align: center;
`;

export const StyledFormItem = styled(Form.Item)`
.ant-form-item-label{
    width: ${tokens.sizes[80]}
}
.ant-col-12 {
    display: block;
     flex: none;
}
.ant-input{
    width: ${tokens.sizes['3xs']}
}
`;

export const StyledModal = styled(Modal)`
.ant-modal-content{
    width: ${tokens.sizes[400]};
}
    cursor: move;
    top: ${tokens.sizes[400]};
    left: ${tokens.sizes[700]};
`;
