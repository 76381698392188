import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Select, Input, message, Space, Switch } from 'antd';
import { Link, useHistory, useParams } from 'react-router-dom';
import { AppRoutes } from '../../../../lib/constants';
import { StyledButton, StyledMenu, FormItem } from '../../maintenancetable.style';
import { useTranslation } from 'react-i18next';
import { addRepairAgent, updateRepairAgent, getRepairAgentById } from '../../../../api/repairAgents';
import { getStateList } from '../../../../api/states';
import { getClientId } from '../../../../api/depots';
import { preventNonNumericalInput } from '../../../../utils';

const AddRepairAgents = () => {

  const { t } = useTranslation();
  const { Option } = Select;
  const [states, setStates] = useState([]);
  const [clientId, setclientId] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [numberReq, setnumberReq] = useState(false);
  const [emailReq, setemailReq] = useState(false);
  const [form] = Form.useForm();
  const history = useHistory();
  const param = useParams();

  const id = param.id;

  useEffect(() => {
    getStateList().then(response => {
      setStates(response?.data);
    })
      .catch(error => {
        alert(error);
      });

    getClientId().then((response) => {
      setclientId(response.data);
    });

    id && getRepairAgentById(id).then(response => {
      const values = response?.data;
      setInitialValues({
        'ClientID': values.clientId,
        'RepairAgent': values.name,
        'Dealership': values.dealership,
        'NotifyByEmail': values.notifyByEmail,
        'EmailAddress': values.emailAddress,
        'UserNotifyBySMS': values.notifyBySms,
        'UserSMSPhoneNumber': values.smsPhoneNumber,
        'Suburb/Town/City': values.suburb,
        'State': values.state,
        'Postcode': values.postCode
      });
    })
  }, [id]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
    setemailReq(initialValues?.NotifyByEmail);
    setnumberReq(initialValues?.UserNotifyBySMS);
  }, [initialValues, form]);

  const onSubmit = ((values) => {
    const payload =
    {
      id: id,
      clientId: values["ClientID"] ?? '',
      name: values["RepairAgent"]?.trim() ?? '',
      dealership: values["Dealership"] ?? false,
      notifyByEmail: values["NotifyByEmail"] ?? false,
      emailAddress: values["EmailAddress"] ?? '',
      notifyBySms: values["UserNotifyBySMS"] ?? false,
      smsPhoneNumber: values["UserSMSPhoneNumber"] ?? '',
      suburb: values["Suburb/Town/City"]?.trim() ?? '',
      state: values["State"] ?? '',
      postCode: values["Postcode"] ?? ''
    }
    if (id) {
      updateRepairAgent(payload).then(() => {
        message.success(t('message.editrepairagentsuccess'));
        form.resetFields();
        history.push(AppRoutes.RepairAgents);
      }).catch((error) => {
        message.error(error?.response?.data);
      });
    }
    else {
      addRepairAgent(payload).then(() => {
        message.success(t('message.addrepairagentsuccess'));
        form.resetFields();
        history.push(AppRoutes.RepairAgents);
      }).catch((error) => {
        message.error(error?.response?.data);
      });
    }
  });

  return (
    <>
      <div>
        <StyledMenu mode="horizontal">
          <StyledMenu.Item><Link to={AppRoutes.Home}>{t('menu.home')}</Link></StyledMenu.Item>
        </StyledMenu>
      </div>
      <div style={{ padding: 50 }}>
        <Row>
          <Col xs={24} xl={16} sm={16} lg={16}>
            <Form form={form} labelCol={{ span: 12, offset: 1 }} wrapperCol={{ span: 10 }} layout="horizontal" onFinish={onSubmit}>
              <FormItem name="ClientID" label="CLIENT ID" rules={[{ required: true, message: "'Client Id' is Required" }]}>
                <Select style={{ width: 200 }} placeholder='Please select'>
                  {clientId?.map(client => {
                    return <Option key={client} value={client}>{client}</Option>
                  })}
                </Select>
              </FormItem>
              <FormItem name="RepairAgent" label="REPAIR AGENT" rules={[{ required: true, message: "'Repair Agent' is Required" }, { max: 50 }]}>
                <Input maxLength={50} disabled={id ? true : false} placeholder="Enter Repair Agent" />
              </FormItem>
              <FormItem name="Dealership" label="DEALERSHIP" valuePropName="checked">
                <Switch checkedChildren="Yes" unCheckedChildren="No" ></Switch>
              </FormItem>
              <FormItem name="NotifyByEmail" label="NOTIFY BY EMAIL" valuePropName="checked">
                <Switch checkedChildren="Yes" unCheckedChildren="No" onChange={(Ans) => setemailReq(Ans)}></Switch>
              </FormItem>
              <FormItem name="EmailAddress" label="EMAIL ADDRESS" rules={emailReq ? [{ max: 100 }, { type: 'email', message: "Enter a valid Email Address" }, { required: true, message: "'Email Address' is Required" }] : [{ max: 100 }, { type: 'email', message: "Enter a valid Email Address" }]}>
                <Input maxLength={100} placeholder="Enter Email Address" />
              </FormItem>
              <FormItem name="UserNotifyBySMS" label="USER NOTIFY BY SMS" valuePropName="checked">
                <Switch checkedChildren="Yes" unCheckedChildren="No" onChange={(Ans) => setnumberReq(Ans)}></Switch>
              </FormItem>
              <FormItem name="UserSMSPhoneNumber" label="USER SMS PHONE NUMBER" rules={numberReq ?[{ required: true, message: "'User SMS Phone Number' is Required" }, {pattern: /^[+;](61\d{9})/,message: t('validation.phoneerror'),}] : [{pattern: /^[+;](61\d{9})/,message: t('validation.phoneerror'),}]}>
                <Input
                  placeholder="Enter Phone Number"
                  onKeyPress={(e) => preventNonNumericalInput(e)}
                  onInput={(e) => { if (e.currentTarget.value.slice(0, 3) === "+61") e.currentTarget.value = e.currentTarget.value.slice(0, 12) }}
                />
              </FormItem>
              <FormItem name="Suburb/Town/City" label="SUBURB/TOWN/CITY">
                <Input maxLength={50} placeholder="Enter Suburb/Town/City" />
              </FormItem>
              <FormItem name="State" label="STATE" rules={[{ required: true, message: "'State' is Required" }]}>
                <Select style={{ width: 200 }} placeholder='Please select' >
                  {states?.map(state => {
                    return <Option key={state?.stateCode} value={state?.stateName}>{state?.stateName}</Option>
                  })}
                </Select>
              </FormItem>
              <FormItem name="Postcode" label="POSTCODE" rules={[{ max: 7 }]}>
                <Input type="number" placeholder="Enter Postcode" />
              </FormItem>
              <Space style={{ display: 'flex', justifyContent: 'center', paddingTop: 30 }}>
                <FormItem>
                  <StyledButton htmlType="submit">{t('lblsubmit')}</StyledButton>
                </FormItem>
                <FormItem>
                  <StyledButton><Link to={AppRoutes.RepairAgents}>{t('lblcancel')}</Link></StyledButton>
                </FormItem>
              </Space>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default AddRepairAgents;
