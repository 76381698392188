import { Menu } from 'antd';
import styled from 'styled-components';
import { tokens } from '../../lib/tokens';
import { mq } from '../../lib/mediaQueries';

export const StyledMenu = styled(Menu)`
    background-color: ${tokens.palette.grey[3]};
    font-weight: 600;    
`;

export const StyledTab = styled(Menu.Item)`
    background-color: ${tokens.palette.grey[3]};
    font-weight: 600;
    width: ${tokens.sizes.md};
    font-size: ${tokens.sizes[16]};

    ${mq(768)}{
        width: ${tokens.sizes.full};        
    }
`;

export const StyledDiv = styled.div`
    padding: ${tokens.sizes[30]};
`;