import React, { useState, useEffect , useRef, useContext } from 'react';
import { Col, Row, Form, Select, Input, Space, message , Modal , Button } from "antd";
import { useTranslation } from 'react-i18next';
import { StyledMenu, StyledButton, StyledTable, StyledHeader, StyledContent, FormItem, FormContent } from './RegisterFault.styles';
import { Link, useHistory, useParams } from 'react-router-dom';
import { AppRoutes } from '../../lib/constants';
import { getDepotsData } from '../../api/depots';
import { getFleetVehiclesData } from '../../api/fleetVehicles';
import { getRepairAgentsData } from '../../api/repairAgents';
import { getSecondarySubRepairerData } from '../../api/secondarySubRepairer';
import { addVehicleFaultData, getCaseId, getVehicleCategory, getVehicleFaultById, updateVehicleFaultData, addVehicleNote, deleteVehicleNote, getAttachments, uploadAttachment , cancelFaultCase , getFileBlob} from '../../api/registerFault';
import { getUpdateList } from '../../api/casestatus';
import moment from 'moment';
import { getFunctionGroupsData } from '../../api/functionGroups';
import ProgressBar from '../../components/ProgressBar';
import { DeleteOutlined } from '@ant-design/icons';
import { Modules } from '../../lib/roles';
import { UserContext } from '../../lib/userContext';
import { PRIMARY_REPAIR_AGENT , SECONDARY_REPAIR_AGENT } from '../../utils/usertypes';

import './registerfault.css';

const RegisterFault = () => {

  const { t } = useTranslation();

  const history = useHistory();
  const param = useParams();

  const user = useContext(UserContext);

  const permission = user?.permission?.moduleNoAccess;

  console.log(permission);
  const id = param.id;
  const ref = useRef();

  const [form] = Form.useForm();
  const [cancelFaultCaseForm] = Form.useForm();
  const { Option } = Select;
  const { TextArea } = Input;
  const [depotList, setDepotList] = useState([]);
  const [filteredVehicles, setFilteredVehicles] = useState([]);
  const [vehicleList, setVehicleList] = useState([]);
  const [repairAgentList, setRepairAgentList] = useState([]);
  const [subRepairAgentList, setSubRepairAgentList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [filteredSubRepairAgent, setFilteredSubRepairAgent] = useState([]);
  const [FunctionGroupList, setFunctionGroupList] = useState([]);
  const [filteredFunctionGroup, setFilteredFunctionGroup] = useState([]);
  const [caseStatus, setCaseStatus] = useState([]);
  const [showUploadOption, setShowUploadOption] = useState(false);
  const [showCancelFaultCase, setShowCancelFaultCase] = useState(false);
  const [deleteFaultCaseId, setDeleteFaultCaseId] = useState();
  const [dataSource, setDataSource] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [files, setFiles] = useState([]);
  const [repairWorkList, setrepairWorkList] = useState([]);
  const [noteText, setNoteText] = useState();
  const [noteList, setNoteList] = useState([]);
  const [isRepairWorkListReq, setIsRepairWorkListReq] = useState(false);
  const [prevCaseStatus, setPrevCaseStatus] = useState();
  const [currentCaseStatus, setCurrentCaseStatus] = useState();
  const [isSubmitButtonVisible, setIsSubmitButtonVisible] = useState(true);
  const [initialCaseStatus, setInitialCaseStatus] = useState();
  const [caseStatusDisabledValueList, setCaseStatusDisabledValueList] = useState([]);
  const [depotDisabled, setDepotDisabled] = useState(false);
  const [iscaseStatusDisable, setIscaseStatusDisable] = useState(false);
  const [caseRegisteredDate, setCaseRegisteredDate] = useState('');

  // for switchting action button disablity
  const [ caseCancelDisable , setCaseCancelDisable ] = useState(false);
  const [ submitBtnDisabled , setSubmitBtnDisabled ] = useState(false);
  const [depotCase,setdepotCase] = useState(false);

  const [disableRepairAgent,setDisableRepairAgent] = useState(false);
  const [disableSubRepairAgent,setDisableSubRepairAgent] = useState(false);

  const userDetail = useContext(UserContext);

  useEffect(() => {
    getDepotsData().then(response => {
      setDepotList(response?.data);
    })
      .catch(error => {
        alert(error);
      });
    getFleetVehiclesData().then(response => {
      setVehicleList(response?.data);
    })
      .catch(error => {
        alert(error);
      });
    getRepairAgentsData().then(response => {
      setRepairAgentList(response?.data);
    })
      .catch(error => {
        alert(error);
      });
    getSecondarySubRepairerData().then(response => {
      const subrepairArray = response?.data;
      setSubRepairAgentList(subrepairArray);
      setFilteredSubRepairAgent(subrepairArray);
      let subRepairAgent = subrepairArray.filter(data => data.name === user?.subrepairAgent);
      if(user?.userRole === SECONDARY_REPAIR_AGENT.SSGRP1) {

        form.setFieldsValue({'RepairAgent':subRepairAgent[0]?.primaryRepairAgent})
        form.setFieldsValue({'SubContractor':subRepairAgent[0]?.name})
        setDisableRepairAgent(true);
        setDisableSubRepairAgent(true);
      } else if(user?.userRole === PRIMARY_REPAIR_AGENT.PSGRP) {
        form.setFieldsValue({'RepairAgent':subRepairAgent[0]?.primaryRepairAgent})
        setFilteredSubRepairAgent(subrepairArray.filter(x => x.primaryRepairAgent === subRepairAgent[0]?.primaryRepairAgent));
        setDisableRepairAgent(true);
      }
    }).catch(error => {
        alert(error);
    });
    getVehicleCategory().then(response => {
      setCategoryList(response?.data);
    }).catch(error => {
        alert(error);
    });
    !id && getCaseId().then(response => {
      form.setFieldsValue({
        'FaultReferenceId': response?.data
      })
    })
      .catch(error => {
        alert(error);
      });
    getFunctionGroupsData().then(response => {
      setFunctionGroupList(response?.data);
      setFilteredFunctionGroup(response?.data);
    }).catch(error => {
        alert(error);
    });
    getUpdateList().then(response => {
      setCaseStatus(response?.data);
    }).catch(error => {
        alert(error);
    });

    if(user?.userRole === "DEPOT") {
      form.setFieldsValue({
        'Depot': user.depotName ?? '',
      });
      setDepotDisabled(true);
    }

    id && getVehicleFaultById(id).then(response => {
      const values = response?.data;
      setInitialValues({
        'operatorName':values?.operatorName,
        'BusModelType': values?.series,
        'BusChassisNumber': values?.chassisNumber,
        'BusVIN': values?.vin,
        'WarrantyStartDate': moment(values?.warrantyStartDate).format("DD/MM/YYYY"),
        'WarrantyEndDate': moment(values?.warrantyEndDate).format("DD/MM/YYYY"),
        'BusBodyNumber': values?.bodyNumber,
        'WarrantyConditions': values?.warrantyCondition,
        'FaultReferenceId': values?.caseId,
        'Depot': values?.depotOrigin,
        'BusFleetNumber': values?.fleetNumber,
        'BusMileage': values?.mileage,
        'Category': values?.faultCategory,
        'FaultDescription': values?.initialDescription,
        'RepairAgent': values?.supplierRepairAgent,
        'SubContractor': values?.subContractor,
        'ScopeGroup': values?.initialFaultScope,
        'CaseStatus': values?.caseCurrentStatus,
        'RepairWorkList' : values?.vehicleRepairWork,
        'CaseRepairCompletedBy' : values?.caseRepairCompletedByUser,
        'CaseRepairCompletedDate' : values?.caseRepairCompletedDate

      });
      setCaseRegisteredDate(values?.caseRegisteredDate);
      setNoteList(values?.vehicleNoteTextLogEntities)
      setInitialCaseStatus(values?.caseCurrentStatus);
      getAttachmentList(values?.caseId);

      if(values?.caseCurrentStatus === "CASE_CREATED" || values?.caseCurrentStatus === "CASE_ACCEPTED" || values?.caseCurrentStatus === "BUS_RECEIVED" ) {
        if(values?.supplierRepairAgent.toLowerCase() === "volgren" && userDetail.userRole === 'SSGR1') {
           setIscaseStatusDisable(true);
         } else {
           setIscaseStatusDisable(false);
         }
      } else if (values?.caseCurrentStatus === "REPAIR_COMPLETED" && userDetail.userRole !== 'DEPOT' && userDetail.userRole !== 'ADMIN') {
        setIscaseStatusDisable(true);
      }

      if (values?.caseCurrentStatus === "CASE_CANCELLED" || values?.caseCurrentStatus === "REPAIR_COMPLETED" ||  values?.caseCurrentStatus === "RECEIPTED_BY_DEPOT"){
        setCaseCancelDisable(true);
      }

      if (values?.caseCurrentStatus === "CASE_CANCELLED" || values?.caseCurrentStatus === "RECEIPTED_BY_DEPOT") {
        setSubmitBtnDisabled(true);
      }
      let list = [{
        caseId: values?.caseId,
        workedRapairList: values?.vehicleRepairWork,
        dateCreated: values?.repairWrkShpCompletedDate,
        timeCreated: values?.repairWrkShpCompletedDate,
        userCreator: values?.repairWrkShpCompletedRecordedBy
      }]
      setrepairWorkList(list);
      setdepotCase(values?.depotCase);
      setPrevCaseStatus(values?.caseCurrentStatus);
      setCurrentCaseStatus(values?.caseCurrentStatus);
      console.log(values?.depotCase, values?.caseCurrentStatus)
      switch (values?.caseCurrentStatus) {
        case 'CASE_CREATED':
          if (!values?.depotCase && user?.userRole !== "DEPOT") {
            setCaseStatusDisabledValueList([2, 3, 4]);
          } else {
            setCaseStatusDisabledValueList([1, 2, 3, 4]);
          }
          break;
        case 'CASE_ACCEPTED':
          if (user?.userRole === "DEPOT") {
            setCaseStatusDisabledValueList([0,1,2,3,4]);
          } else {
            setCaseStatusDisabledValueList([0, 3, 4]);
          }
          break;
        case 'BUS_RECEIVED':
          if (user?.userRole === "DEPOT") {
            setCaseStatusDisabledValueList([0,1,2,3,4]);
          } else {
            setCaseStatusDisabledValueList([0, 1, 4]);
          }
          break;
        case 'RECEIPTED_BY_DEPOT':
          setIsSubmitButtonVisible(false);
          setCaseStatusDisabledValueList([0, 1, 2, 3]);
          break;
        case 'REPAIR_COMPLETED':
            // yeah this looks weird. but there were different previous conditions and uncertain requirements so
            // just let it be incase they say turn it back
          if (!values?.depotCase && user?.userRole === "DEPOT") {
            setCaseStatusDisabledValueList([0, 1, 2]);
          }
          else if (!values?.depotCase) {
            setCaseStatusDisabledValueList([0, 1, 2]);
          } else {
            setCaseStatusDisabledValueList([0, 1, 2]);
          }
          break;
        default:
          return null;
      }

    });
  }, [id, form , user , userDetail]);

  const handleDepotChange = (depot) => {
    if(id)
      return;
    const operatorName = depotList.filter(d => d.depotName === depot)[0]?.depotOperatorName;

    let vehicles = vehicleList?.filter(x => x.vehicleOperatorName === operatorName);
    let sortedVehicles = vehicles.sort(sortByVehicleFleetNumber);
    setFilteredVehicles(sortedVehicles);
    form.setFieldsValue({ 'BusFleetNumber': null });
  }
  function sortByVehicleFleetNumber( a, b ) {
    if ( a.vehicleFleetNumber < b.vehicleFleetNumber ){
      return -1;
    }
    if ( a.vehicleFleetNumber > b.vehicleFleetNumber ){
      return 1;
    }
    return 0;
  }
  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues, form]);
  useEffect(() => {
    if(vehicleList.length > 0 && depotList.length > 0 && user.depotName) {
      handleDepotChange(user.depotName);
    }
    // eslint-disable-next-line
  },[vehicleList, depotList, user.depotName])

  const getAttachmentList = (faultCaseNumber) => {
    getAttachments(faultCaseNumber).then(response => {
      setDataSource(response?.data);
    })
      .catch(error => {
        alert(error);
      })
  }

  // when click on home reset
  const resetnavigation = () => {
    localStorage.removeItem("filtered");
    localStorage.removeItem("sortOrder");
    localStorage.setItem('currentPage', '0');
    window.location.href = "/home";
    // let filterSource = JSON.parse(localStorage.getItem('filtered'));
    // filterSource['vehicleHistory'] = {};
    // localStorage.setItem('filtered',JSON.stringify(filterSource))
    // localStorage.setItem('currentPage', '0');
  }

  const setValuesOnFleetNoChange = (value) => {
    let filteredData = filteredVehicles.find(x => x.vehicleFleetNumber === value);

    form.setFieldsValue({
      'BusModelType': filteredData.vehicleSeries,
      'BusChassisNumber': filteredData.vehicleChassisNumber,
      'BusVIN': filteredData.vehicleVin,
      'WarrantyStartDate': moment(filteredData.vehicleWarrantyStartDate).format("DD/MM/YYYY"),
      'WarrantyEndDate': moment(filteredData.vehicleWarrantyEndDate).format("DD/MM/YYYY"),
      'BusBodyNumber': filteredData.vehicleBodyNumber,
      'WarrantyConditions': filteredData.vehicleWarrantyCondition,
      'operatorName' : filteredData.vehicleOperatorName,
    });
  }

  const onSubmit = ((values) => {
    const payload =
    {
      id: id,
      clientId: "PTAWA",
      operatorName:values['operatorName'] ?? "",
      caseId: values["FaultReferenceId"] ?? "",
      depotOrigin: values["Depot"] ?? "",
      fleetNumber: values["BusFleetNumber"] ?? "",
      type: values["Category"] ?? "",
      chassisNumber: values["BusChassisNumber"] ?? "",
      series: values["BusModelType"] ?? "",
      vin: values["BusVIN"] ?? "",
      bodyNumber: values["BusBodyNumber"] ?? "",
      warrantyStartDate: values["WarrantyStartDate"]? moment(values["WarrantyStartDate"], "DD/MM/YYYY").format("YYYY-MM-DD") : "",
      warrantyEndDate: values["WarrantyEndDate"] ? moment(values["WarrantyEndDate"], "DD/MM/YYYY").format("YYYY-MM-DD") : "",
      warrantyCondition: values["WarrantyConditions"] ?? '',
      mileage: values["BusMileage"]?.trim() ?? "",
      faultCategory: values["Category"] ?? "",
      initialDescription: values["FaultDescription"]?.trim() ?? "",
      supplierRepairAgent: values["RepairAgent"] ?? "",
      subContractor: values["SubContractor"] ?? "",
      initialFaultScope: values["ScopeGroup"] ?? "",
      caseCurrentStatus: values["CaseStatus"] ?? "CASE_CREATED",
      caseRegisteredDate: id ? moment(new Date(caseRegisteredDate)).format("YYYY-MM-DD"): moment().format("YYYY-MM-DD"),
      vehicleRepairWork: values["RepairWorkList"] ?? "",
      depotCase: false, // depotCase: user?.userRole === "DEPOT" ?? false, 27 jan Changes 129
      caseRepairCompletedDate: (values["CaseStatus"] === "REPAIR_COMPLETED" ?  new Date(new Date().toString().split('GMT')[0]+' UTC').toISOString() : initialValues?.CaseRepairCompletedDate),
      repairWrkShpCompletedDate:(values["CaseStatus"] === "REPAIR_COMPLETED" ? new Date(new Date().toString().split('GMT')[0]+' UTC').toISOString() : initialValues?.CaseRepairCompletedDate),
      caseRepairCompletedBy: (values["CaseStatus"] === "REPAIR_COMPLETED" ? userDetail.userId : initialValues?.CaseRepairCompletedBy),
      repairWrkShpCompletedRecordedBy: (values["CaseStatus"] === "REPAIR_COMPLETED" ? userDetail.userId : initialValues?.CaseRepairCompletedBy),
    }

    if (id) {
      updateVehicleFaultData(payload).then(() => {
        message.success(t('message.editfaultsuccess'));
        history.push(AppRoutes.Home);
      }).catch(() => {
        message.error(t('message.editfaulterror'));
      });
    }
    else {
      addVehicleFaultData(payload).then(() => {
        message.success(t('message.addfaultdetailssuccess'));
        history.push(AppRoutes.Home);
      }).catch(() => {
        message.error(t('message.addfaultdetailserror'));
      });
    }
  });


  const handleFileChange = (event) => {
    let fileName = event.target?.files[0]?.name;
    let fileSize = event.target?.files[0]?.size;
    fileName = fileName.toString().toLowerCase();
    let sizeinMB = parseInt(fileSize / (1024*1024)).toFixed(0);

    let exts = ['.jpg','.png','.xml','.json','.pdf','.gif','.txt'];
    if((new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$')).test(fileName)) {
      if(sizeinMB < parseInt(process.env.REACT_APP_FILE_UPLOAD_LIMIT)) {
        setFiles(event.target.files)
      } else {
        event.target.value = "";
        message.error(t('message.fileistoolarge'));
      }
    } else {
      event.target.value = "";
      setFiles([]);
      message.error(t('message.filetypeinvalid'));
    }
  };

  const handleFileUpload = async () => {
    if(files.length) {
      let caseId = form.getFieldValue("FaultReferenceId");
      const fileContent = new FormData();
      fileContent.append("faultCaseNumber", caseId);
      fileContent.append("file", files[0], files[0].name);
      const res = await uploadAttachment(fileContent);
      if (res.status === 200) {
        message.success(t('message.fileuploadsucces'));
      }
      else {
        message.success(t('message.fileuploaderror'));
      }
      ref.current.value = "";
      getAttachmentList(caseId);
    } else {
      message.error(t('message.fileselectmust'));
    }
  };

  const columns = [
    {
      title: 'Attachment',
      dataIndex: 'attachment',
      key: 'attachment',
      width: '22.5rem',
    },
    {
      title: 'Creation date',
      dataIndex: 'creationDate',
      key: 'creationDate',
      render: (record) => {
        return (
          moment(record?.creationDate).format("DD/MM/YYYY hh:mm:ss A")
        );
      },
      width: '12.5rem',
    },
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      width: '12.5rem',
    },
  ];

  const workList = [
    {
      title: 'Case ID',
      dataIndex: 'caseId',
      key: 'caseId',
      width: '15%',
    },
    {
      title: 'Worked Repair List',
      dataIndex: 'workedRapairList',
      key: 'workedRapairList',
      width: '40%',
    },
    {
      title: 'Date Created',
      dataIndex: 'dateCreated',
      key: 'dateCreated',
      render: (record) => {
        return (
          moment(record).format("DD/MM/YYYY")
        );
      },
      width: '15%',
    },
    {
      title: 'Time Created',
      dataIndex: 'timeCreated',
      key: 'timeCreated',
      render: (record) => {
        return (
          moment(record).format("hh:mm:ss A")
        );
      },
      width: '15%',
    },
    {
      title: 'User Creator',
      dataIndex: 'userCreator',
      key: 'userCreator',
      width: '15%',
    },
  ];

  const deleteCurrentFaultCase = (id) => {
    setDeleteFaultCaseId(id);
    setShowCancelFaultCase(true);
  }

  const handleDeleteFaultCaseSubmit = (values) => {
    const payload = {
      "reason": values["ReasonToDeleteFaultCase"]?.trim() ?? "",
      "faultId": deleteFaultCaseId ?? ""
    }

    cancelFaultCase(payload).then(() => {
        message.success(t('message.faultdeletedsuccess'));
        setCaseCancelDisable(true);
        history.push(AppRoutes.Home);
      }).catch(() => {
        message.error(t('message.faultdeleterror'));
      });

    setShowCancelFaultCase(false);
    setDeleteFaultCaseId(null);
    cancelFaultCaseForm.resetFields();
  }

  const handleCancelFaultCaseCancel = () => {
    setShowCancelFaultCase(false);
    setDeleteFaultCaseId(null);
    cancelFaultCaseForm.resetFields();
  }

  const handlegetFile = (id) => {

    getFileBlob(id).then(response => {
      var windowUrl = window.URL || window.webkitURL;
      // defined type [ Note : this is for csv please dont change it]
      var contentType = response.headers['content-type'];
      // this will create the Blob object
      var blobdata = new Blob([response.data],{type:contentType})
      var url = windowUrl.createObjectURL(blobdata);
      window.open(url,'_blank');
      windowUrl.revokeObjectURL(url);
    }).catch(err => {
      message.error(t('downloadfile.error'));
    })

  };

  const attachmentList = dataSource?.map((data) => {
    return {
      key: data?.id.toString(),
      attachment: (
        <Button type="link" onClick={() =>handlegetFile(data?.id)}>
          {data?.fileName}
        </Button>
      ),
      creationDate: data?.createdAt,
      user: data?.createdBy,
    };
  });

  const notelistData = noteList?.map((data) => {
    return {
      key: data?.id.toString(),
      vehicleFaultCaseId: data?.vehicleFaultCaseId,
      vehicleNoteNo: data?.vehicleNoteNo,
      vehicleNoteText: data?.vehicleNoteText,
      vehicleNoteType: data?.vehicleNoteType,
      createdDate: data?.createdAt,
      user: data?.createdBy,
    };
  });

  const handleNoteChange = (event) => {
    setNoteText(event.target.value);
  };

  const handleNoteUpload = () => {
    if(noteText) {
      const notePayload = {
        vehicleFaultCaseId: form.getFieldValue('FaultReferenceId'),
        vehicleNoteText: noteText.trim(),
        vehicleNoteType: "COMM-NOTE",
        vehicleFaultEntity: { id: parseInt(id) }
      };
      addVehicleNote(notePayload).then((response) => {
        var updatedNoteList = [response.data].concat(noteList);
        setNoteList(updatedNoteList);
      });
      form.setFieldsValue({ 'Note': null });
      setNoteText();
    } else {
      message.error(t('message.notemusthaveerr'));
    }
  };

  const handleNoteDelete = (id) => {
    deleteVehicleNote(id).then(() => {
      setNoteList(noteList.filter(note => note.id.toString() !== id.toString()));
    });
  }

  const noteColumns = [
    {
      title: 'Case ID',
      dataIndex: 'vehicleFaultCaseId',
      key: 'vehicleFaultCaseId',
      width: '7%',
    },
    {
      title: 'Note no.',
      dataIndex: 'vehicleNoteNo',
      key: 'vehicleNoteNo',
      width: '6%',
    },
    {
      title: 'Text note',
      dataIndex: 'vehicleNoteText',
      key: 'vehicleNoteText',
      width: '22.5%',
    },
    {
      title: 'Date created',
      dataIndex: '',
      key: 'createdDate',
      render: (record) => {
        return (
          moment(record?.createdDate).format("DD/MM/YYYY hh:mm:ss A")
        );
      },
      width: '9%',
    },
    {
      title: 'User',
      dataIndex: '',
      key: 'user',
      render: (record) => {
        return (
          record?.user
        );
      },
      width: '8%',
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (record) => {
        if (record?.vehicleNoteType === "COMM-NOTE") {
          return (
            <span
              className='delete-icon-style'
              onClick={permission?.includes(Modules.NoteAndDocument) ? (()=>{ return false }) : (() => { handleNoteDelete(record.key) })}
              style={permission?.includes(Modules.NoteAndDocument) ? {cursor:'not-allowed'} : {}}
            >
              <DeleteOutlined />
            </span>
          )
        }
      },
      width: '5%',
    },
  ];

  const checkIsNumber = (e) => {
    e = e || window.event;
    var charCode = (typeof e.which === "undefined") ? e.keyCode : e.which;
    var charStr = String.fromCharCode(charCode);

    if (!charStr.match(/^[0-9]+$/))
        e.preventDefault();
  };

  return (
    <>
      <div>
        <StyledMenu mode="horizontal">
          <StyledMenu.Item key={t('menu.home')}><Link to={AppRoutes.Home} onClick={resetnavigation}>{t('menu.home')}</Link></StyledMenu.Item>
        </StyledMenu>
      </div>
      <div style={{ paddingTop: 40 }}>
        {id && depotCase && <ProgressBar active={caseStatus?.findIndex(x => x.caseStatusValue === initialCaseStatus)} isdepotUser={depotCase} />}
        {id && !depotCase && <ProgressBar active={caseStatus?.findIndex(x => x.caseStatusValue === initialCaseStatus)} isdepotUser={depotCase} />}
      </div>
      <FormContent>
        <Form form={form} labelCol={{ span: 12 }} wrapperCol={{ span: 24 }} layout="vertical" onFinish={onSubmit}>
        <Row>
            <Col xs={24} lg={24} md={24} xl={24}>
              <Space style={{ display: 'flex', justifyContent: 'center', paddingTop: 30 }}>
                {id && <Form.Item>
                  <StyledButton onClick={() => deleteCurrentFaultCase(id)} disabled={caseCancelDisable || permission?.includes(Modules.CancelCaseButton)}>{t('lblremovecase')}</StyledButton>
                </Form.Item>}
               {id && <Form.Item>
                  <StyledButton><Link to={AppRoutes.Home}>{t('lblpreviousscreen')}</Link></StyledButton>
                </Form.Item> }
                {id && <>
                  <Form.Item style={{ width: 120 }} />
                  <Form.Item>
                    <label>Case Status</label>
                  </Form.Item>
                  <Form.Item name="CaseStatus">
                    <Select placeholder='Please select' style={{ width: 160 }} onChange={(value) => {
                      switch (value) {
                        case 'REPAIR_COMPLETED':
                          prevCaseStatus === 'BUS_RECEIVED' ? setIsRepairWorkListReq(true) : setIsRepairWorkListReq(false);
                          break;
                        default:
                          setIsRepairWorkListReq(false);
                          break;
                      };
                      setIsSubmitButtonVisible(true);
                      setCurrentCaseStatus(value);
                       }} disabled={ permission?.includes(Modules.StatusDropDown) || iscaseStatusDisable}>
                      {caseStatus?.map((status, i) => {
                        return <Option key={i} value={status.caseStatusValue} disabled={caseStatusDisabledValueList.includes(i)}>{status.caseStatusLabel}</Option>
                      })}
                    </Select>
                  </Form.Item>
                </>}
                {isSubmitButtonVisible && id &&
                  <Form.Item>
                    <StyledButton htmlType="submit" className={submitBtnDisabled ? 'disabledbutton' : ''} style={(id) && { backgroundColor: '#2269c3', color: 'white' }} disabled={submitBtnDisabled}>{t('lblsubmit')}</StyledButton>
                  </Form.Item>
                }
              </Space>
            </Col>
          </Row>
          <Row align="middle">
            <Col xs={24} md={12} lg={8}>
              <FormItem name="FaultReferenceId" label="Fault reference ID">
                <Input disabled />
              </FormItem>
            </Col>
            <Col xs={24} md={12} lg={6} xl={6}>
              <FormItem name="Depot" label="Depot" rules={[{ required: true }]} >
                <Select placeholder='Please select' disabled={depotDisabled} onChange={value => handleDepotChange(value)}>
                  {depotList?.map(depot => {
                    return <Option key={depot?.id} value={depot?.depotName}>{depot?.depotName}</Option>
                  })}
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row align="middle">
            <Col xs={24} md={12} lg={8}>
              <FormItem name="BusFleetNumber" label="Bus fleet number" rules={[{ required: true, message: "Bus fleet number is required" }]}>
                <Select placeholder='Please select' onChange={(value) => setValuesOnFleetNoChange(value)} disabled={id ? true : false}>
                  {filteredVehicles?.map(vehicle => {
                    return <Option key={vehicle?.id} value={vehicle?.vehicleFleetNumber}>{vehicle?.vehicleFleetNumber}</Option>
                  })}
                </Select>
              </FormItem>
              <Form.Item label="operatorName" name="operatorName" style={{ display: 'none' }}>
                  <Input type="text" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <FormItem name="BusModelType" label="Bus model type">
                <Input disabled />
              </FormItem>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <FormItem name="WarrantyStartDate" label="Warranty start date" rules={[{ required: true, message: "Warranty start date is required" }]}>
                <Input disabled />
              </FormItem>
            </Col>
          </Row>
          <Row align="middle">
            <Col xs={24} md={12} lg={8}>
              <FormItem name="BusChassisNumber" label="Bus chassis number" rules={[{ required: true, message: "Bus chassis number is required" }]}>
                <Input disabled />
              </FormItem>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <FormItem name="BusVIN" label="Bus VIN">
                <Input disabled />
              </FormItem>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <FormItem name="WarrantyEndDate" label="Warranty end date" rules={[{ required: true, message: "Warranty end date is required" }]}>
                <Input disabled />
              </FormItem>
            </Col>
          </Row>
          <Row align="middle">
            <Col xs={24} md={12} lg={8}>
              <FormItem name="BusBodyNumber" label="Bus body number">
                <Input disabled />
              </FormItem>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <FormItem name="WarrantyConditions" label="Warranty Conditions">
                <TextArea rows={2} disabled />
              </FormItem>
            </Col>
          </Row>
          <Row align="middle">
            <Col xs={24} md={12} lg={8}>
              <FormItem name="BusMileage" label="Bus mileage" rules={[{ required: true, message: "Bus mileage is required" }]}>
              <Input onKeyPress={(e) => checkIsNumber(e)} onChange={(e) => form.setFieldsValue({ 'BusMileage': e.currentTarget.value.replace(/\D/g, '') })}/>
              </FormItem>
            </Col>
            <Col xs={24} md={12} lg={6} xl={6}>
              <FormItem name="Category" label="Category" rules={[{ required: true }]}>
                <Select placeholder='Please select' >
                  {categoryList?.map(category => {
                    return <Option key={category} value={category}>{category}</Option>
                  })}
                </Select>
              </FormItem>
            </Col>
            <Col xs={24} md={12} lg={8}></Col>
          </Row>
          <Row align="middle">
            <Col xs={24} md={24} lg={16}>
              <FormItem name="FaultDescription" label="Fault description" rules={[{ required: true, message: "Fault description is required" }]}>
                <TextArea rows={2} />
              </FormItem>
            </Col>
          </Row>
          <Row align="middle">
            <Col xs={24} md={12} lg={8}>
              <FormItem name="RepairAgent" label="Repair agent" rules={[{ required: true, message: "Repair agent is required" }]}>
                <Select placeholder='Please select' onChange={(value) => { setFilteredSubRepairAgent(subRepairAgentList?.filter(x => x.primaryRepairAgent === value)); form.setFieldsValue({ 'SubContractor': null, 'ScopeGroup': null }); setFilteredFunctionGroup(FunctionGroupList); }} >
                  {repairAgentList?.map(repairAgent => {
                    return <Option key={repairAgent?.id} value={repairAgent?.name}>{repairAgent?.name}</Option>
                  })}
                </Select>
              </FormItem>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <FormItem name="SubContractor" label="Sub contractor" rules={[{ required: true, message: "Sub contractor is required" }]}>
                <Select placeholder='Please select' onChange={(value) => { setFilteredFunctionGroup(subRepairAgentList?.filter(x => x.name === value)[0]?.functionGroupEntities); form.setFieldsValue({ 'ScopeGroup': null }); }} >
                  {filteredSubRepairAgent?.map(subRepairAgent => {
                    return <Option key={subRepairAgent?.id} value={subRepairAgent?.name}>{subRepairAgent?.name}</Option>
                  })}
                </Select>
              </FormItem>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <FormItem name="ScopeGroup" label="Scope/Fnc group" rules={[{ required: true, message: "Scope/Fnc group is required" }]}>
                <Select placeholder='Please select' >
                  {filteredFunctionGroup?.map(functionGroup => {
                    return <Option key={functionGroup?.id} value={functionGroup?.name}>{functionGroup?.name}</Option>
                  })}
                </Select>
              </FormItem>
            </Col>
          </Row>
          {id &&
            <>
              <Row align="middle">
                <Col xs={24} md={20} lg={20}>
                  <FormItem name="Note" label="Note">
                    <TextArea rows={2} showCount onChange={(e) => handleNoteChange(e)} disabled={permission?.includes(Modules.NoteAndDocument)}/>
                  </FormItem>
                </Col>
                <Col xs={24} md={4} lg={4}>
                  <FormItem>
                    <StyledButton className={'updatenotebtn'} onClick={handleNoteUpload} disabled={permission?.includes(Modules.NoteAndDocument)}>{t('lblnoteupload')}</StyledButton>
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col xs={24} lg={24} md={24}>
                  <FormItem>
                    <StyledTable
                      columns={noteColumns}
                      dataSource={notelistData}
                      pagination={false}
                      scroll={{y: 200}}
                    />
                  </FormItem>
                </Col>
              </Row>
            <Row>
              <Col xs={24} md={24} lg={24} xl={24}>
                <FormItem name="RepairWorkList" label="Repair Details" style={Object.assign(!isRepairWorkListReq && {display: 'none'}, { paddingTop: 25})} rules={[{ required: isRepairWorkListReq, message: "Repair Worked List on Vehicle is required" }]}>
                  <TextArea rows={3} />
                </FormItem>
              </Col>
            </Row>
           {currentCaseStatus === 'RECEIPTED_BY_DEPOT' && <Row>
              <Col xs={24} lg={24} md={24}>
                <FormItem label="Repair details">
                  <StyledTable
                    columns={workList}
                    dataSource={repairWorkList}
                    pagination={false}
                  />
                </FormItem>
              </Col>
            </Row>}
            </>
          }
          <Row>
            <Col>
              <FormItem>
                <StyledButton className={'supportuploadbtn'} onClick={() => setShowUploadOption(true)} disabled={id && permission?.includes(Modules.NoteAndDocument)}>{t('lblsupportingdocupload')}</StyledButton>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={24} md={24}>
              <FormItem>
                <StyledTable
                  columns={columns}
                  dataSource={attachmentList}
                  pagination={false}
                />
              </FormItem>
            </Col>
          </Row>
          {showUploadOption &&
            <div>
              <Row>
                <Col xs={24} lg={24} md={24}>
                  <StyledHeader>Enter document to upload</StyledHeader>
                  <hr />
                </Col>
              </Row>
              <StyledContent>
                <Row>
                  <Col xs={24} lg={4} md={4}>
                    <FormItem>
                      Document:
                    </FormItem>
                  </Col>
                  <Col xs={24} lg={8} md={8}>
                    <FormItem>
                      <input type="file" name="file"
                       accept="image/png, image/jpeg, .pdf, .doc, .xml, .gif, .jpg, .txt, .json"
                       ref={ref} onChange={(e) => handleFileChange(e)} />
                    </FormItem>
                  </Col>
                  <Col xs={24} lg={12} md={12}>
                    <FormItem style={{ alignItems: "flex-end" }}>
                      <StyledButton onClick={handleFileUpload}>{t('lblupload')}</StyledButton>
                    </FormItem>
                  </Col>
                </Row>
                <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Col>
                    <FormItem>
                      <StyledButton onClick={() => setShowUploadOption(false)}>{t('lblcancelupload')}</StyledButton>
                    </FormItem>
                  </Col>
                </Row>
              </StyledContent>
            </div>
          }
          <Row>
            <Col xs={24} lg={24} md={24} xl={24}>
              <Space style={{ display: 'flex', justifyContent: 'center', paddingTop: 30 }}>
                {id && <Form.Item>
                  <StyledButton onClick={() => deleteCurrentFaultCase(id)} disabled={caseCancelDisable || permission?.includes(Modules.CancelCaseButton)}>{t('lblremovecase')}</StyledButton>
                </Form.Item>}
                <Form.Item>
                  <StyledButton><Link to={AppRoutes.Home} >{t('lblpreviousscreen')}</Link></StyledButton>
                </Form.Item>
                {id && <>
                  <Form.Item style={{ width: 120 }} />
                  <Form.Item>
                    <label>Case Status</label>
                  </Form.Item>
                  <Form.Item name="CaseStatus">
                 <Select placeholder='Please select' style={{ width: 160 }} onChange={(value) => {
                      switch (value) {
                        case 'REPAIR_COMPLETED':
                          prevCaseStatus === 'BUS_RECEIVED' ? setIsRepairWorkListReq(true) : setIsRepairWorkListReq(false);
                          break;
                        default:
                          setIsRepairWorkListReq(false);
                          break;
                      };
                      setIsSubmitButtonVisible(true);
                      setCurrentCaseStatus(value);
                       }} disabled={ permission?.includes(Modules.StatusDropDown) || iscaseStatusDisable}>
                      {caseStatus?.map((status, i) => {
                        return <Option key={i} value={status.caseStatusValue} disabled={caseStatusDisabledValueList.includes(i)}>{status.caseStatusLabel}</Option>
                      })}
                    </Select>
                  </Form.Item>
                </>}
                {isSubmitButtonVisible &&
                  <Form.Item>
                    <StyledButton htmlType="submit" className={submitBtnDisabled ? 'disabledbutton' : ''} style={(id) && { backgroundColor: '#2269c3', color: 'white' }} disabled={submitBtnDisabled}>{t('lblsubmit')}</StyledButton>
                  </Form.Item>
                }
              </Space>
            </Col>
          </Row>
        </Form>
      </FormContent>

      <Modal
        visible={showCancelFaultCase}
        title={t('confirm.deletefault')}
        onCancel={handleCancelFaultCaseCancel}
        onOk={cancelFaultCaseForm.submit}
        okText="Yes"
        cancelText="No"
      >
        <Form form={cancelFaultCaseForm} onFinish={handleDeleteFaultCaseSubmit} layout="vertical">
          <FormItem name="ReasonToDeleteFaultCase" label="Reason to cancel" rules={[{ required: true, message: "Reason to cancel is required" }]}>
            <Input />
          </FormItem>
        </Form>
      </Modal>
    </>
  );
}


export default RegisterFault;
