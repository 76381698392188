import React, { useEffect, useState } from 'react';
import { StyledButton, StyledMenu, StyledTable, StyledFormItem } from '../maintenancetable.style';
import { AppRoutes } from '../../../lib/constants';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Menu, Row, Col, Input, Space, Button, Modal, Form, message } from 'antd'
import { calculateColumnsWidth } from '../../../lib/countColumnsWidth';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { getFunctionGroupsData, deleteFunctionGroupData, updateFunctionGroup, addFunctionGroup } from '../../../api/functionGroups';
const FunctionGroups = () => {

  const { t } = useTranslation();
  const [source, setSource] = useState([]);
  const [isEditModal, setIsEditModalVisible] = useState(false);
  const [editId, setEditID] = useState();
  const [addForm] = Form.useForm();
  const [editForm] = Form.useForm();
  const [initialValues, setInitialValues] = useState({});

  const maxWidthPerCell = 600;
  const tableHeight = 500;
  const { confirm } = Modal;

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Action',
      key: 'action',
      render: (record) => (
        <>
          <Space size="middle">
            <Button type="link" size="small" onClick={() => openeditfunctiongroupModal(record.key, record.name)}>{t('lbledit')}</Button>
            <Button type="link" size="small" onClick={() => deleteFunctionGroup(record.key)}>{t('lbldelete')}</Button>
          </Space>
        </>)
    },
  ];

  const dataSource = source?.map((data) => {
    return {
      key: data?.id.toString(),
      name: data?.name,
    }
  });

  const dataTable = calculateColumnsWidth(columns, dataSource, maxWidthPerCell);

  useEffect(() => {
    getFunctionGroups();
  }, []);


  useEffect(() => {
    editForm.setFieldsValue(initialValues);
  }, [editForm, initialValues]);

  const getFunctionGroups = () => {
    getFunctionGroupsData().then(response => {
      setSource(response?.data);
    })
      .catch(error => {
        alert(error);
      })
  }


  const onSubmit = ((values) => {
    const match = source.find(data => data.name === values["Name"]);
    if (!match) {
      const payload =
      {
        createdAt: moment(),
        createdBy: "Test1",  //TODO: this will be current loggedin username
        lastModifiedAt: moment(),
        lastModifiedBy: "Test1", //TODO: this will be current loggedin username
        name: values["Name"]?.trim(),
      }
      addFunctionGroup(payload).then(() => {
        message.success(t('message.addfunctiongroupsuccess'));
        getFunctionGroups();
        addForm.resetFields();
      }).catch(() => {
        message.error(t('message.addfunctiongrouperror'));
      });

    }
    else {
      message.error(t('message.uniqfunctiongrouperror'));
    }
  });

  const deleteFunctionGroup = (id) => {
    if (id) {
      confirm({
        title: t('confirm.deletefunctiongroup'),
        icon: <ExclamationCircleOutlined />,
        onOk() {
          deleteFunctionGroupData(id).then((response) => {
            if (response.status === 200) {
              let filteredData = source.filter(data => data.id.toString() !== id.toString());
              setSource(filteredData);
              message.success(t('message.deletefunctiongroupsuccss'))
            } else {
              message.error(t('message.deletefunctiongriuperror'));
            }
          }).catch(() => {
            message.error(t('message.deletefunctiongriuperror'));
          })
        },
      });
    }
  }

  const openeditfunctiongroupModal = (id, name) => {
    setEditID(id)
    setInitialValues({
      'Name': name,
    });
    setIsEditModalVisible(true)
  }



  const editfunctiongroup = (value) => {
    const match = source.find(data => data.name === value["Name"]);
    if (!match) {
      const payload =
      {
        id: editId,
        name: value["Name"]?.trim(),
      }
      updateFunctionGroup(payload).then(() => {
        message.success(t('message.editfunctiongroupsuccess'));
        getFunctionGroups();
        editForm.resetFields();
        closeEditFunctionGroup();
      }).catch(() => {
        message.error(t('message.editfunctiongrouperror'));
      });
    }
    else {
      message.error(t('message.uniqfunctiongrouperror'));
    }
  };

  const closeEditFunctionGroup = () => {
    setIsEditModalVisible(false);
    setEditID();
  };


  return (
    <>
      <div>
        <StyledMenu mode="horizontal">
          <Menu.Item key={t('menu.home')}>
            <Link to={AppRoutes.Home}>{t('menu.home')}</Link>
          </Menu.Item>
        </StyledMenu>
      </div>
      <div xs={24} xl={16} sm={16} lg={16} style={{ padding: '3rem' }}>
        <Form form={addForm} labelCol={{ span: 12, offset: 1 }} wrapperCol={{ span: 10 }} layout="horizontal" onFinish={onSubmit}>
          <Row>
            <Col xs={16} xl={16} sm={16} lg={12} >
              <Form.Item name="Name" label="FUNCTION GROUP NAME" rules={[{ required: true, message: "'Function Group Name' is Required" }]} >
                <Input maxLength={50} />
              </Form.Item>
            </Col>
            <Col xs={9} xl={8} sm={8} lg={12} >
              <Form.Item >
                <StyledButton htmlType="submit" style={{ margin: '0px' }}>Add</StyledButton>
              </Form.Item></Col>
          </Row>
        </Form>
        <Modal title={t('menu.editfunctiongroup')} visible={isEditModal} onOk={editForm.submit} onCancel={closeEditFunctionGroup} okText="Update">
          <Form form={editForm} labelCol={{ span: 12, offset: 1 }} wrapperCol={{ span: 10 }} layout="horizontal" onFinish={editfunctiongroup}>
            <Row>
              <Col xs={24} xl={24} sm={24} lg={24} >
                <StyledFormItem name="Name" label="FUNCTION GROUP NAME" rules={[{ required: true, message: "'Function Group Name' is Required" }]} >
                  <Input maxLength={50} />
                </StyledFormItem>
              </Col>
            </Row>
          </Form>
        </Modal>

      </div>
      <div>
        <StyledTable
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          scroll={{ x: dataTable.tableWidth, y: tableHeight }}
          bordered
        />
      </div>

    </>
  );
}

export default FunctionGroups;
