const Roles = {
    DEPOT: "DEPOT",
    SSGR1: "SSGR1",
    SSGR2: "SSGR2",
    FM: "FM",
    AM: "AM",
    PSGRP: "PSGRP",
    PTAFM: "PTAFM",
    PCMP: "PCMP",
    ADMIN: "ADMIN"
}

export const Modules = {
    RegisterFault: "RegisterFault",
    UpdateFaultCase: "UpdateFaultCase",
    CancelCaseButton: "CancelCaseButton",
    StatusDropDown: "StatusDropDown",
    NoteAndDocument: "NoteAndDocument",
    ModifyRepairagent: "ModifyRepairagent",
    ExportData: "ExportData",
    Admin: "Admin"
}

// Roles with modules that they have no access
export const RoleBasedNoAccess = {
    [Roles.DEPOT]: [Modules.ModifyRepairagent, Modules.Admin],
    [Roles.SSGR1]: [Modules.ModifyRepairagent, Modules.Admin],
    [Roles.SSGR2]: [Modules.RegisterFault, Modules.ModifyRepairagent, Modules.Admin, Modules.CancelCaseButton, Modules.StatusDropDown],
    [Roles.FM]: [Modules.RegisterFault, Modules.UpdateFaultCase, Modules.NoteAndDocument, Modules.ModifyRepairagent, Modules.Admin, Modules.CancelCaseButton, Modules.StatusDropDown],
    [Roles.AM]: [Modules.RegisterFault, Modules.UpdateFaultCase, Modules.NoteAndDocument, Modules.ModifyRepairagent, Modules.Admin, Modules.CancelCaseButton, Modules.StatusDropDown],
    [Roles.PSGRP]: [Modules.Admin],
    [Roles.PTAFM]: [Modules.Admin],
    [Roles.PCMP]: [Modules.Admin]
}
