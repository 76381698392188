export function preventNonNumericalInput(e) {
    e = e || window.event;
    var charCode = (typeof e.which === "undefined") ? e.keyCode : e.which;
    if (charCode === 43) {
        return true;
    }
    var charStr = String.fromCharCode(charCode);

    if (!charStr.match(/^[0-9]+$/))
        e.preventDefault();
}
