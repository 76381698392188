import React, { useContext } from "react";
import { images } from '../../../assets/image';
import { StyledContent, StyledImage, StyledHeader, StyledText } from "./MainLayout.styles";
import { useTranslation } from 'react-i18next';
import { UserContext } from "../../../lib/userContext";

export const MainLayout = ({ props, children }) => {
  const { t } = useTranslation();
  
  const user = useContext(UserContext);

  return (
    <>
      <StyledContent>
        <StyledImage src={images.logo.default} alt=""  style={{marginRight:'20px'}}/>
        <StyledHeader>{t('mainheader')}</StyledHeader>
        <span>{props}</span>
      </StyledContent>
      <StyledText>{`${user?.userName} - ${user?.userRole}`}</StyledText>
        <div>
          {children}
        </div>
    </>
  );
}

export default MainLayout;
