import React, { useState, useEffect } from 'react';
import { Row, Col, Form, DatePicker, Select, Input, message, Space } from 'antd';
import { Link, useHistory, useParams } from 'react-router-dom';
import { AppRoutes } from '../../../../lib/constants';
import { StyledButton, StyledMenu, FormItem } from '../../maintenancetable.style';
import { useTranslation } from 'react-i18next';
import { addNewVehicle, getFleetVehicleDataById, editVehicle, getFleetVehicleYears, getVehicleModelSeries } from '../../../../api/fleetVehicles';
import { getVehicleOperators } from '../../../../api/vehicleoperator';
import { getVehicleBrands } from '../../../../api/vehiclebrands';
import moment from 'moment';

const AddFleetVehicles = () => {

  const { t } = useTranslation();
  const { Option } = Select;
  const [brandData, setBrandData] = useState([]);
  const [operatorData, setOperatorData] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [vehicleYear, setVehicleYear] = useState([]);
  const [vehicleModelSeries, setVehicleModelSeries] = useState([]);
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const history = useHistory();
  const param = useParams();

  const id = param.id;

  useEffect(() => {
    getVehicleOperators().then(response => {
      setOperatorData(response?.data);
    })
      .catch(error => {
        alert(error);
      });

    getVehicleBrands().then(response => {
      setBrandData(response?.data);
    })
      .catch(error => {
        alert(error);
      });

    getFleetVehicleYears().then(response => {
      setVehicleYear(response?.data);
    })
      .catch(error => {
        alert(error);
      });

    getVehicleModelSeries().then(response => {
      setVehicleModelSeries(response?.data);
    })
      .catch(error => {
        alert(error);
      });

    id && getFleetVehicleDataById(id).then(response => {
      const values = response?.data;
      setInitialValues({
        'OperatorName': values.vehicleOperatorName,
        'VehicleBrand': values.vehicleBrand,
        'VehicleFleetNumber': values.vehicleFleetNumber,
        'VehicleBodyNumber': values.vehicleBodyNumber,
        'VehicleChassisNumber': values.vehicleChassisNumber,
        'VehicleModelSeries': values.vehicleSeries,
        'VehicleVIN': values.vehicleVin,
        'VehicleModelYear': values.vehicleModelYear,
        'VehicleWTYStartDate': moment(values.vehicleWarrantyStartDate),
        'VehicleWTYEndDate': moment(values.vehicleWarrantyEndDate),
        'VehicleWTYCondition': values.vehicleWarrantyCondition
      });
    })
  }, [id]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues, form]);

  const onSubmit = ((values) => {
    const payload =
    {
      id: id,
      vehicleType: '',
      vehicleOperatorName: values["OperatorName"],
      vehicleBodyNumber: values["VehicleBodyNumber"]?.trim() ?? '',
      vehicleBrand: values["VehicleBrand"] ?? '',
      vehicleChassisNumber: values["VehicleChassisNumber"]?.trim() ?? '',
      vehicleFleetNumber: values["VehicleFleetNumber"]?.trim() ?? '',
      vehicleSeries: values["VehicleModelSeries"] ?? '',
      vehicleModelYear: values["VehicleModelYear"] ?? '',
      vehicleVin: values["VehicleVIN"]?.trim() ?? '',
      vehicleWarrantyCondition: values["VehicleWTYCondition"]?.trim() ?? '',
      vehicleWarrantyEndDate: values["VehicleWTYEndDate"] ? moment(values["VehicleWTYEndDate"]).format("YYYY-MM-DD") : '',
      vehicleWarrantyStartDate: values["VehicleWTYStartDate"] ? moment(values["VehicleWTYStartDate"]).format("YYYY-MM-DD") : ''
    }
    if (id) {
      editVehicle(payload).then(() => {
        message.success(t('message.editvehiclesuccess'));
        form.resetFields();
        history.push(AppRoutes.FleetVehicles);
      }).catch((error) => {
        message.error(error?.response?.data);
      });
    }
    else {
      addNewVehicle(payload).then(() => {
        message.success(t('message.addvehiclesuccess'));
        form.resetFields();
        history.push(AppRoutes.FleetVehicles);
      }).catch((error) => {
        message.error(error?.response?.data);
      });
    }
  });

  return (
    <>
      <div>
        <StyledMenu mode="horizontal">
          <StyledMenu.Item key={t('menu.home')}><Link to={AppRoutes.Home}>{t('menu.home')}</Link></StyledMenu.Item>
        </StyledMenu>
      </div>
      <div style={{ padding: 50 }}>
        <Row>
          <Col xs={24} xl={16} sm={16} lg={16}>
            <Form form={form} labelCol={{ span: 12, offset: 1 }} wrapperCol={{ span: 10 }} layout="horizontal" onFinish={onSubmit}>
              <FormItem name="OperatorName" label="OPERATOR NAME" placeholder='Please select' rules={[{ required: true, message: "'Operator Name' is Required" }]}>
                <Select style={{ width: 200 }} placeholder='Please select'>
                  {operatorData?.map(operator => {
                    return <Option key={operator?.operatorName} value={operator?.operatorName}>{operator?.operatorName}</Option>
                  })}
                </Select>
              </FormItem>
              <FormItem name="VehicleBrand" label="VEHICLE BRAND" rules={[{ required: true, message: "'Vehicle Brand' is Required" }]}>
                <Select style={{ width: 200 }} placeholder='Please select'>
                  {brandData?.map(brand => {
                    return <Option key={brand} value={brand}>{brand}</Option>
                  })}
                </Select>
              </FormItem>
              <FormItem name="VehicleFleetNumber" label="VEHICLE FLEET NUMBER" rules={[{ required: true, message: "'Vehicle Fleet Number' is Required" }, { max: 10 }]}>
                <Input maxLength={10} placeholder="Enter Vehicle Fleet Number" />
              </FormItem>
              <FormItem name="VehicleBodyNumber" label="VEHICLE BODY NUMBER" rules={[{ max: 6 }]}>
                <Input maxLength={6} placeholder="Enter Vehicle Body Number" />
              </FormItem>
              <FormItem name="VehicleChassisNumber" label="VEHICLE CHASSIS NUMBER" rules={[{ required: true, message: "'Vehicle Chassis Number' is Required" }, { max: 6 }]}>
                <Input maxLength={6} placeholder="Enter Vehicle Chassis Number" />
              </FormItem>
              <FormItem name="VehicleModelSeries" label="VEHICLE MODEL SERIES" rules={[{ required: true, message: "'Vehicle Model Series' is Required" }, { max: 7 }]}>
                <Select style={{ width: 200 }} placeholder='Please select'>
                  {vehicleModelSeries?.map(model => {
                    return <Option key={model} value={model}>{model}</Option>
                  })}
                </Select>
              </FormItem>
              <FormItem name="VehicleVIN" label="VEHICLE VIN" rules={[{ max: 17 }]}>
                <Input maxLength={17} placeholder="Enter Vehicle VIN" />
              </FormItem>
              <FormItem name="VehicleModelYear" label="VEHICLE MODEL YEAR" rules={[{ required: true, message: "'Vehicle Model Year' is Required" }]}>
                <Select style={{ width: 200 }} placeholder='Please select'>
                  {vehicleYear?.map(year => {
                    return <Option key={year} value={year}>{year}</Option>
                  })}
                </Select>
              </FormItem>
              <FormItem name="VehicleWTYStartDate" label="VEHICLE WTY START DATE" rules={[{ required: true, message: "'Vehicle WTY Start Date' is Required" }]}>
                <DatePicker format={'DD/MM/YYYY'} />
              </FormItem>
              <FormItem name="VehicleWTYEndDate" label="VEHICLE WTY END DATE" rules={[{ required: true, message: "'Vehicle WTY End Date' is Required" }]}>
                <DatePicker format={'DD/MM/YYYY'} />
              </FormItem>
              <FormItem name="VehicleWTYCondition" label="VEHICLE WTY CONDITION">
                <TextArea rows={4} maxLength={100} showCount placeholder="Enter Vehicle WTY Condition" />
              </FormItem>
              <Space style={{ display: 'flex', justifyContent: 'center', paddingTop: 30 }}>
                <FormItem>
                  <StyledButton htmlType="submit">{t('lblsubmit')}</StyledButton>
                </FormItem>
                <FormItem>
                  <StyledButton><Link to={AppRoutes.FleetVehicles}>{t('lblcancel')}</Link></StyledButton>
                </FormItem>
              </Space>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default AddFleetVehicles;
