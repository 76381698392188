import {request} from '../lib/request';
const GET_VEHICLE_OPERATOR_LIST = 'list';

const vehicleoperatorApi = request({
    baseURL: process.env.REACT_APP_BASE_API + 'vehicle-operator/'  
});

export const getVehicleOperators = async () => {
    return await vehicleoperatorApi.get(GET_VEHICLE_OPERATOR_LIST);
}
  
  
