import React from 'react';
import { Menu } from 'antd';
import { StyledMenu, StyledTab, StyledDiv } from './Admin.styles';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AppRoutes } from '../../lib/constants';

const Admin = () => {

  const { t } = useTranslation();

  return (
    <>
      <div>
        <StyledMenu>
          <StyledMenu.Item key={AppRoutes.Home}><Link to={AppRoutes.Home}>{t('menu.home')}</Link></StyledMenu.Item>
        </StyledMenu>
      </div>
      <StyledDiv>        
          <Menu>
            <StyledTab key={AppRoutes.FleetVehicles}><Link to={AppRoutes.FleetVehicles}>{t('admintab.maintainfleetvh')}</Link></StyledTab>
            <StyledTab key={AppRoutes.Depots}><Link to={AppRoutes.Depots}>{t('admintab.maintaindepots')}</Link></StyledTab>
            <StyledTab key={AppRoutes.RepairAgents}><Link to={AppRoutes.RepairAgents}>{t('admintab.maintainrepairagent')}</Link></StyledTab>
            <StyledTab key={AppRoutes.SecondarySubRepairer}><Link to={AppRoutes.SecondarySubRepairer}>{t('admintab.maintainsecondarysubrepairer')}</Link></StyledTab>
            <StyledTab key={AppRoutes.ApplicationUsers}><Link to={AppRoutes.ApplicationUsers}>{t('admintab.maintainappusers')}</Link></StyledTab>
            <StyledTab key={AppRoutes.FunctionGroups}><Link to={AppRoutes.FunctionGroups}>{t('admintab.maintainfunctiongroups')}</Link></StyledTab>
            <StyledTab key={AppRoutes.FunctionGroupAllocation}><Link to={AppRoutes.FunctionGroupAllocation}>{t('admintab.maintainfunctiongroupallocation')}</Link></StyledTab>          
          </Menu>        
      </StyledDiv>
    </>
  );
}

export default Admin;
