import React from 'react';
import { InternalServerErrorPage } from '../../InternalServerError';

class ErrorBoundary extends React.PureComponent{

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {    
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <InternalServerErrorPage />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
