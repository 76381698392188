import { request } from '../lib/request';

const casestatusApi = request({
  baseURL: process.env.REACT_APP_BASE_API + 'case-status/'
});

export const getCaseStatus = async () => {
    return await casestatusApi.get('search-list');
}

export const getUpdateList = async () => {
  return await casestatusApi.get('update-list');
}
