import { Menu, Button, Table, Form } from 'antd';
import styled from 'styled-components';
import { tokens } from '../../lib/tokens';

export const StyledMenu = styled(Menu)`
    background-color: ${tokens.palette.grey[3]};
    font-weight: 600;    
`;

export const StyledButton = styled(Button)`
    margin: ${tokens.sizes[10]};
    background-color: ${tokens.palette.grey[3]};
    border: ${tokens.sizes[1]} solid ${tokens.palette.black[0]};
    font-weight: 600;
`;

export const StyledTable = styled(Table)`
    .ant-table-thead > tr > th {
        background: ${tokens.palette.grey[2]};
        color: ${tokens.palette.black[0]};
        line-height: 0.1;  
    }    
    .ant-table-placeholder {
        display:none
    }
    .ant-table-tbody > tr > td {
        word-break: break-all;
    }
`;

export const StyledHeader = styled.h2`
    background-color: ${tokens.palette.grey[6]};
    width: ${tokens.sizes.full};
    padding: ${tokens.sizes[5]};
    color: ${tokens.palette.white[0]};
`;

export const StyledContent = styled.div`
    padding: ${tokens.sizes[40]};
    width: ${tokens.sizes.full};
    background-color: ${tokens.palette.grey[1]};
`;

export const FormItem = styled(Form.Item)`
    padding: 0 ${tokens.sizes[28]};
    margin-bottom: ${tokens.sizes[5]};
`;

export const FormContent = styled.div`
    padding: ${tokens.sizes[16]};
    max-width: ${tokens.sizes['5xl']};
    margin: 0 auto;
`;