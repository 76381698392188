import React, { useEffect, useState } from 'react';
import { withRouter, useLocation, useHistory } from 'react-router-dom';
import PageSpinner from './components/PageSpinner';
import ErrorBoundary from './components/Layout/ErrorBoundary';
import { Routes } from './components/Routes';
import 'antd/dist/antd.css';
import { AppRoutes } from './lib/constants';
import Cookies from 'js-cookie';
import { getUserByUserId } from './api/users';
import { UserContext } from './lib/userContext';
import { RoleBasedNoAccess } from './lib/roles';

function App() {
  const location = useLocation();
  const [userDetails, setUserDetails] = useState({});
  const userIdFromCookie = !!JSON.parse(String(process.env.REACT_APP_USER_ID_FROM_COOKIE).toLowerCase());
  const history = useHistory();

  useEffect(() => {
    if (!userIdFromCookie) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const user_id = urlParams.get('user_id');

      if (user_id) {
        Cookies.remove('WSG-UID', { path: "/" });
        Cookies.set('WSG-UID', user_id);
        history.push(AppRoutes.Home.replace('user_id=' + user_id, ''));
      }
    }
  })

  useEffect(() => {
    getUserByUserId(Cookies.get('WSG-UID')).then((response) => {     
      setUserDetails({
        userName: response?.data?.userName,
        userRole: response?.data?.levelType,
        depotName: response?.data?.depotName,
        authenticated: true,
        subrepairAgent:response?.data?.supplierRepairAgentName,
        userId:response?.data?.userId,
        permission: {
          moduleNoAccess: RoleBasedNoAccess[`${response?.data?.levelType}`],
        }
      })
    }).catch(() => {
      setUserDetails({
        authenticated: false,
      })
    })
  }, []);

  if (location.pathname === AppRoutes.Login) {
    window.location.assign(location.pathname.replace(AppRoutes.Login, AppRoutes.Home));
  }

  return userDetails?.authenticated !== undefined ? (
    <ErrorBoundary>
      <div>
        <React.Suspense fallback={<PageSpinner />}>
          <UserContext.Provider value={userDetails}>
            <Routes />
          </UserContext.Provider>
        </React.Suspense>
      </div>
    </ErrorBoundary>
  ) : null;
}

export default withRouter(App);