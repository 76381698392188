import { Menu , Button , Table, Form, Checkbox  } from 'antd';
import styled from 'styled-components';
import { tokens } from '../../lib/tokens';
import { mq } from '../../lib/mediaQueries';

export const StyledMenu = styled(Menu)`
    background-color: ${tokens.palette.grey[3]};
    font-weight: 600;    
`;

export const StyledTab = styled(Menu.Item)`
    background-color: ${tokens.palette.grey[3]};
    font-weight: 600;
    width: ${tokens.sizes.md};
    font-size: ${tokens.sizes[16]};

    ${mq(768)}{
        width: ${tokens.sizes.full};        
    }
`;

export const StyledDiv = styled.div`
    padding: ${tokens.sizes[30]};
`;

export const StyledButton = styled(Button)`
    margin: ${tokens.sizes[10]};
    background-color: ${tokens.palette.grey[3]};
    text-align-last: right;        
    border: ${tokens.sizes[1]} solid ${tokens.palette.black[0]};
    font-weight: 600;
`;

export const StyledTable = styled(Table)`
    .ant-table-thead > tr > th {
        background: ${tokens.palette.grey[8]};
        color: ${tokens.palette.white[0]};
        padding-left:8px
    }
    .ant-table-tbody > tr > td {    
        padding:8px;
        line-height: normal;
    }
    .ant-table {
        line-height:0.5
    }
`;

export const StyledUserTable = styled(Table)`
    .ant-table-thead > tr > th {
        background: ${tokens.palette.grey[8]};
        color: ${tokens.palette.white[0]};
        padding-left: ${tokens.sizes[6]};
    }
    .ant-table-tbody > tr > td {
        padding: ${tokens.sizes[6]};
    }
    .ant-table {
        line-height: 1.2
    }
`;

export const StyledFormItem = styled(Form.Item)`
.ant-col-12 {
    display: block;
     flex: none;
}
.ant-input{
    width: 17rem
}
`;

export const StyledCheckbox = styled(Checkbox.Group)`
.ant-checkbox-group-item {
    display: block;
    margin-right: 0;
    font-size: large;
}
.ant-checkbox-inner {
    display: inline-block;
}`;

export const FormItem = styled(Form.Item)`
    margin-bottom: ${tokens.sizes[5]};
`;

export const SubRepairerHeader = styled.p`
    font-weight: 500;
    font-size: 15;
`;
