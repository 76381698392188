import { request} from '../lib/request';

const functionGroupApi = request({
    baseURL: process.env.REACT_APP_BASE_API + 'function-group'
});

export const getFunctionGroupsData = async () => {
    return await functionGroupApi.get('/list');
}
export const deleteFunctionGroupData = async (id) => {
  return await functionGroupApi.delete(`/${id}`);
}

export const addFunctionGroup = async (payload) => {
  return await functionGroupApi.post('', payload);
}

export const updateFunctionGroup = async (payload) => {
  return await functionGroupApi.put('', payload);
}