import {request} from '../lib/request';

const repairAgentsApi = request({
  baseURL: process.env.REACT_APP_BASE_API + 'vehicle-repair-agent'
});

export const getRepairAgentsData = async () => {
  return await repairAgentsApi.get('/list');
}

export const addRepairAgent = async (payload) => {
  return await repairAgentsApi.post('', payload);
}

export const deleteRepairAgent = async (id) => {
  return await repairAgentsApi.delete(`/${id}`);
}

export const getRepairAgentById = async (id) => {
  return await repairAgentsApi.get(`/${id}`);
}

export const updateRepairAgent = async (payload) => {
  return await repairAgentsApi.put('', payload);
}
