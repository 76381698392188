
import React, { useState, useEffect, useContext, createRef } from 'react';
import { Menu, Select, Space, Tooltip, Row, Form, Input, message, Modal } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { AppRoutes } from '../../lib/constants';
import moment from "moment";
import { images } from '../../assets/image';
import { StyledButton, StyledSelect, StyledDatePicker, StyledTable, StyledMenu, StyledImage, StyledHeader, StyledFormItem, StyledModal } from './Home.styles';
import { useTranslation } from 'react-i18next';
import { searchFaultData, searchByVehicle } from '../../api/registerFault';
import { getModelSeries } from '../../api/modelseries';
import { getCaseStatus } from '../../api/casestatus';
import { getFaultType } from '../../api/faulttypes';
import './home.css';
import { UserContext } from '../../lib/userContext';
import { Modules } from '../../lib/roles';
import Draggable from 'react-draggable';

const { Option } = Select;

const HomePage = () => {

  const { t } = useTranslation();

  const history = useHistory();

  const draggleRef = createRef();
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });

  const user = useContext(UserContext);
  const permission = user?.permission?.moduleNoAccess;

  const [currentKey, setCurrentKey] = useState('0');
  const [source, setSource] = useState([]);
  const [clonesource, setclonesource] = useState([]);

  const [visible, setVisible] = React.useState(false);
  const [form] = Form.useForm();

  const filterSource = JSON.parse(localStorage.getItem('filtered'));
  const sortData = JSON.parse(localStorage.getItem('sortOrder'));
  const [startDate, setStartDate] = useState(moment(filterSource?.predefined?.startDate ?? '01/01/2020'));
  const [endDate, setEndDate] = useState(moment(filterSource?.predefined?.endDate ?? Date()));


  const [modelSeries, setModelSeries] = useState(filterSource?.predefined?.modelSeries ?? 'ALL');
  const [faultCategory, setFaultCategory] = useState(filterSource?.predefined?.faultCategory ?? 'ALL');
  const [caseStatus, setcaseStatus] = useState(filterSource?.predefined?.caseStatus ?? 'CURRENT_OPEN');

  const [modelSeriesList, setModelSeriesList] = useState([]);
  const [caseStatusList, setCaseStatusList] = useState([]);
  const [faultList, setFaultList] = useState([]);

  let sortIds = [];

  // datatable texts
  const [operator, setoperator] = useState(filterSource?.manual?.operator ?? '');
  const [depot, setdepot] = useState(filterSource?.manual?.depot ?? '');
  const [caseId, setcaseId] = useState(filterSource?.manual?.caseId ?? '');
  const [fleetNo, setfleetNo] = useState(filterSource?.manual?.fleetNumber ?? '');
  const [modelSer, setmodelSer] = useState(filterSource?.manual?.modelservice ?? '');
  const [chassisNumber, setchassisNumber] = useState(filterSource?.manual?.chassis ?? '');
  const [repairAgent, setrepairAgent] = useState(filterSource?.manual?.repairAgent ?? '');
  const [category, setcategory] = useState(filterSource?.manual?.category ?? '');
  const [faultDescription, setfaultDescription] = useState(filterSource?.manual?.faultdesc ?? '');
  const [caseStatuses, setcaseStatuses] = useState(filterSource?.manual?.casestatus ?? '');
  const [wtyExpiry, setwtyExpiry] = useState(filterSource?.manual?.wtyExpiry ?? '');
  const [dateLodged, setdateLodged] = useState(filterSource?.manual?.dateLodged ?? '');
  const [updated, setupdated] = useState(filterSource?.manual?.updated ?? '');
  const [wtyCondition, setwtyCondition] = useState(filterSource?.manual?.wtyCondition ?? '');
  const [initialValues] = useState({
    FleetNo: filterSource?.vehicleHistory?.fleetNumber ?? '',
    BodyNo: filterSource?.vehicleHistory?.bodyNumber ?? '',
    ChassisNo: filterSource?.vehicleHistory?.chassisNumber ?? ''
  });

  const [sortOrder, setSortOrder] = useState({});

  const handleChange = async (order, index) => {
    let updatedOrderData = {};
    if (!order) {
      updatedOrderData = {
        order: 'ascend',
        dataIndex: index
      }
    }
    else if (order === "ascend") {
      updatedOrderData = {
        order: 'descend',
        dataIndex: index
      }
    }
    else if (order === "descend") {
      updatedOrderData = {
        order: '',
        dataIndex: index
      }
    }
    setSortOrder(updatedOrderData);
    localStorage.setItem('sortOrder', JSON.stringify(updatedOrderData));
    updateLocalStorage("", []);
  }



  const onStart = (event, uiData) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement;
    const targetRect = draggleRef?.current?.getBoundingClientRect();
    setBounds({
      left: -targetRect?.left + uiData?.x,
      right: clientWidth - (targetRect?.right - uiData?.x),
      top: -targetRect?.top + uiData?.y,
      bottom: clientHeight - (targetRect?.bottom - uiData?.y),
    });
  }

  const resetFilters = () => {
    localStorage.removeItem("filtered");
    localStorage.removeItem("sortOrder");
    form.resetFields();
    localStorage.setItem('currentPage', '0');
    window.location.href = "/home";
  }

  const handleClick = (e) => {
    setCurrentKey(e);
  }

  useEffect(() => {
    let datatosend = {
      modelSeries: modelSeries,
      faultCategory: faultCategory,
      startDate: startDate.format('DD/MM/YYYY'),
      endDate: endDate.format("DD/MM/YYYY"),
      caseStatus: caseStatus,
    }
    setCurrentKey(localStorage.getItem('currentPage'));
    if (filterSource && Object.values(filterSource?.vehicleHistory).some(x => x !== '')) {
      datatosend = {
        ...datatosend, fleetNumber: filterSource?.vehicleHistory?.fleetNumber,
        bodyNumber: filterSource?.vehicleHistory?.bodyNumber,
        chassisNumber: filterSource?.vehicleHistory?.chassisNumber,
      };
      searchByVehicle(datatosend).then((response) => {
        setSource(response?.data)
        setclonesource(response?.data)
      }).catch(err => console.log(err))

    }
    else {
      searchFaultData(datatosend).then((response) => {
        setSource(response?.data)
        setclonesource(response?.data)
      }).catch(err => console.log(err))
    }

    getModelSeries().then(response => {
      setModelSeriesList(response?.data)
    })

    getCaseStatus().then(response => {
      setCaseStatusList(response.data)
    })

    getFaultType().then(response => {
      let data = response.data;
      let index = data.indexOf('BUS');
      if (index > -1) {
        data.splice(index, 1);
      }
      setFaultList(data)
    })
    // handleChange('ascend', 'category');
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues, form]);

  useEffect(() => {

    let data = clonesource.filter((data) => {
      let opr = data?.operatorName ?? '';
      let depotOrigin = data?.depotOrigin ?? '';
      let caseids = data?.caseId ?? '';
      let fleetNum = data?.fleetNumber ?? '';
      let modelServ = data?.series ?? '';
      let chassNum = data?.chassisNumber ?? '';
      let repairAge = data?.supplierRepairAgent ?? '';
      let categ = data?.faultCategory ?? '';
      let initDesc = data?.initialDescription ?? '';
      let caseStat = data?.caseCurrentStatus ?? '';
      let warend = data?.warrantyEndDate ?? '';
      let datelog = data?.caseRegisteredDate ?? '';
      let upd = data?.lastModifiedAt ?? '';
      let wtcond = data?.warrantyCondition ?? '';

      return opr.toString().toUpperCase().indexOf(operator.toUpperCase()) > -1 && depotOrigin.toString().toUpperCase().indexOf(depot.toUpperCase()) > -1 && caseids.toString().toUpperCase().indexOf(caseId.toUpperCase()) > -1 && fleetNum.toString().toUpperCase().indexOf(fleetNo.toUpperCase()) > -1 && modelServ.toString().toUpperCase().indexOf(modelSer.toUpperCase()) > -1 && chassNum.toString().toUpperCase().indexOf(chassisNumber.toUpperCase()) > -1 && repairAge.toString().toUpperCase().indexOf(repairAgent.toUpperCase()) > -1 && categ.toString().toUpperCase().indexOf(category.toUpperCase()) > -1 && initDesc.toString().toUpperCase().indexOf(faultDescription.toUpperCase()) > -1 && caseStat.toString().toUpperCase().indexOf(caseStatuses.toUpperCase()) > -1 && warend.toString().toUpperCase().indexOf(wtyExpiry.toUpperCase()) > -1 && datelog.toString().toUpperCase().indexOf(dateLodged.toUpperCase()) > -1 && upd.toString().toUpperCase().indexOf(updated.toUpperCase()) > -1 && wtcond.toString().toUpperCase().indexOf(wtyCondition.toUpperCase()) > -1;

    })
    let ids = data.map(d => d.id);
    updateLocalStorage("", ids);
    setSource(data)
    // eslint-disable-next-line
  }, [depot, operator, caseId, fleetNo, modelSer, chassisNumber, repairAgent, caseStatuses, category, faultDescription, wtyExpiry, dateLodged, updated, wtyCondition, clonesource])

  const updateLocalStorage = (type = "", ids = []) => {
    let Localstore = {
      predefined: {
        startDate: startDate,
        endDate: endDate,
        modelSeries: modelSeries,
        faultCategory: faultCategory,
        caseStatus: caseStatus,
      },
      manual: {
        operator: operator,
        depot: depot,
        caseId: caseId,
        fleetNumber: fleetNo,
        modelservice: modelSer,
        chassis: chassisNumber,
        repairAgent: repairAgent,
        casestatus: caseStatuses,
        category: category,
        faultdesc: faultDescription,
        wtyExpiry: wtyExpiry,
        wtyCondition: wtyCondition,
        dateLodged: dateLodged,
        updated: updated
      },
      vehicleHistory: {
        fleetNumber: form.getFieldValue('FleetNo') ?? '',
        bodyNumber: form.getFieldValue('BodyNo') ?? '',
        chassisNumber: form.getFieldValue('ChassisNo') ?? ''
      },
      filterType: type
    }
    if(type !== 'sort') {
      localStorage.setItem('filtered', JSON.stringify(Localstore));
    }
    if(type === 'query') {
      localStorage.setItem('currentPage', currentKey);
    }
    localStorage.setItem('currentIdentifiers', ids);
  }

  const dataSource1 = [0].map((data, index) => {
    return {
      key: "1",
      operator: <Input name="operator" value={operator} placeholder="Search for" onChange={(e) => { setoperator(e.target.value); }} />,
      depot: <Input name="depot" value={depot} placeholder="Search for" onChange={(e) => { setdepot(e.target.value) }} />,
      caseId: <Input name="caseId" value={caseId} placeholder="Search for" onChange={(e) => { setcaseId(e.target.value); }} />,
      fleetNo: <Input name="fleetNo" value={fleetNo} placeholder="Search for" onChange={(e) => { setfleetNo(e.target.value); }} />,
      modelSeries: <Input name="modelSer" value={modelSer} placeholder="Search for" onChange={(e) => { setmodelSer(e.target.value); }} />,
      chassisNumber: <Input name="chassisNumber" value={chassisNumber} placeholder="Search for" onChange={(e) => { setchassisNumber(e.target.value); }} />,
      repairAgent: <Input name="repairAgent" value={repairAgent} placeholder="Search for" onChange={(e) => { setrepairAgent(e.target.value); }} />,
      category: <Input name="category" value={category} placeholder="Search for" onChange={(e) => { setcategory(e.target.value); }} />,
      faultDescription: <Input name="faultDescription" value={faultDescription} placeholder="Search for" onChange={(e) => { setfaultDescription(e.target.value); }} />,
      caseStatus: <Input name="caseStatuses" value={caseStatuses} placeholder="Search for" onChange={(e) => { setcaseStatuses(e.target.value); }} />,
      wtyExpiry: <Input name="wtyExpiry" value={wtyExpiry} placeholder="Search for" onChange={(e) => { setwtyExpiry(e.target.value); }} />,
      dateLodged: <Input name="dateLodged" value={dateLodged} placeholder="Search for" onChange={(e) => { setdateLodged(e.target.value); }} />,
      updated: <Input name="updated" value={updated} placeholder="Search for" onChange={(e) => { setupdated(e.target.value); }} />,
      wtyCondition: <Input name="wtyCondition" value={wtyCondition} placeholder="Search for" onChange={(e) => { setwtyCondition(e.target.value); }} />,
    }

  })

  const dataSource = source?.map((data, index) => {
    // to get value of case status
    let caseStatValue = caseStatusList.filter((d) => d.caseStatusValue === data?.caseCurrentStatus);
    return {
      key: data?.id.toString(),
      operator: data?.operatorName,
      depot: data?.depotOrigin,
      caseId: data?.caseId,
      fleetNo: data?.fleetNumber,
      modelSeries: data?.series,
      chassisNumber: data?.chassisNumber,
      repairAgent: data?.supplierRepairAgent,
      category: data?.faultCategory,
      faultDescription: <Tooltip overlayStyle={{ whiteSpace: 'pre-line' }} title={data?.initialDescription}>{data?.initialDescription}</Tooltip>,
      caseStatus: caseStatValue[0]?.caseStatusLabel,
      wtyExpiry: data?.warrantyEndDate ? moment(data?.warrantyEndDate).format("DD/MM/YYYY") : '',
      dateLodged: data?.createdAt ? moment(data?.createdAt).format("DD/MM/YYYY") : '',
      updated: data?.lastModifiedAt ? moment(data?.lastModifiedAt).format("DD/MM/YYYY") : '',
      wtyCondition: <Tooltip overlayStyle={{ whiteSpace: 'pre-line' }} title={data?.warrantyCondition}>{data?.warrantyCondition}</Tooltip>,
    }

  });

  const columns = [
    {
      title: 'Operator',
      dataIndex: 'operator',
      key: 'operator',
      width: '5.5%',
      sorter: (a, b) => a.operator.localeCompare(b.operator),
      sortOrder: sortData?.dataIndex === "operator" ? sortData?.order : sortOrder.dataIndex === 'operator' && sortOrder.order,
      onHeaderCell: (column) => {
        return {
          onClick: () => {
            handleChange(column.sortOrder, column.dataIndex);
          }
        };
      },
    },
    {
      title: 'Depot',
      dataIndex: 'depot',
      key: 'depot',
      width: '5%',
      sorter: (a, b) => a.depot.localeCompare(b.depot),
      sortOrder: sortData?.dataIndex === "depot" ? sortData?.order : sortOrder.dataIndex === 'depot' && sortOrder.order,
      onHeaderCell: (column) => {
        return {
          onClick: () => {
            handleChange(column.sortOrder, column.dataIndex);
          }
        };
      },
    },
    {
      title: 'Case ID',
      dataIndex: 'caseId',
      key: 'caseId',
      width: '5.5%',
      sorter: (a, b) => a.caseId.localeCompare(b.caseId),
      sortOrder: sortData?.dataIndex === "caseId" ? sortData?.order : sortOrder.dataIndex === 'caseId' && sortOrder.order,
      onHeaderCell: (column) => {
        return {
          onClick: () => {
            handleChange(column.sortOrder, column.dataIndex);
          }
        };
      },
    },
    {
      title: 'Fleet no',
      dataIndex: 'fleetNo',
      key: 'fleetNo',
      width: '5.5%',
      sorter: (a, b) => a.fleetNo.localeCompare(b.fleetNo),
      sortOrder: sortData?.dataIndex === "fleetNo" ? sortData?.order : sortOrder.dataIndex === 'fleetNo' && sortOrder.order,
      onHeaderCell: (column) => {
        return {
          onClick: () => {
            handleChange(column.sortOrder, column.dataIndex);
          }
        };
      },
    },
    {
      title: 'Model series',
      dataIndex: 'modelSeries',
      key: 'modelSeries',
      width: '4%',
      sorter: (a, b) => a.modelSeries.localeCompare(b.modelSeries),
      sortOrder: sortData?.dataIndex === "modelSeries" ? sortData?.order : sortOrder.dataIndex === 'modelSeries' && sortOrder.order,
      onHeaderCell: (column) => {
        return {
          onClick: () => {
            handleChange(column.sortOrder, column.dataIndex);
          }
        };
      },
    },
    {
      title: 'Chassis number',
      dataIndex: 'chassisNumber',
      key: 'chassisNumber',
      width: '5%',
      sorter: (a, b) => a.chassisNumber.localeCompare(b.chassisNumber),
      sortOrder: sortData?.dataIndex === "chassisNumber" ? sortData?.order : sortOrder.dataIndex === 'chassisNumber' && sortOrder.order,
      onHeaderCell: (column) => {
        return {
          onClick: () => {
            handleChange(column.sortOrder, column.dataIndex);
          }
        };
      },
    },
    {
      title: 'Repair agent',
      dataIndex: 'repairAgent',
      key: 'repairAgent',
      width: '6%',
      sorter: (a, b) => a.repairAgent.localeCompare(b.repairAgent),
      sortOrder: sortData?.dataIndex === "repairAgent" ? sortData?.order : sortOrder.dataIndex === 'repairAgent' && sortOrder.order,
      onHeaderCell: (column) => {
        return {
          onClick: () => {
            handleChange(column.sortOrder, column.dataIndex);
          }
        };
      },
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      width: '5%',
      sorter: (a, b) => a.category.localeCompare(b.category),
      sortOrder: sortData?.dataIndex === "category" ? sortData?.order : sortOrder.dataIndex === 'category' && sortOrder.order,
      onHeaderCell: (column) => {
        return {
          onClick: () => {
            handleChange(column.sortOrder, column.dataIndex);
          }
        };
      },
    },
    {
      title: 'Fault description',
      dataIndex: 'faultDescription',
      key: 'faultDescription',
      width: '12%',
      sorter: (a, b) => a.faultDescription?.props?.children?.localeCompare(b.faultDescription?.props?.children),
      sortOrder: sortData?.dataIndex === "faultDescription" ? sortData?.order : sortOrder.dataIndex === 'faultDescription' && sortOrder.order,
      onHeaderCell: (column) => {
        return {
          onClick: () => {
            handleChange(column.sortOrder, column.dataIndex);
          }
        };
      },
    },
    {
      title: 'Case status',
      dataIndex: 'caseStatus',
      key: 'caseStatus',
      width: '8%',
      sorter: (a, b) => a.caseStatus.localeCompare(b.caseStatus),
      sortOrder: sortData?.dataIndex === "caseStatus" ? sortData?.order : sortOrder.dataIndex === 'caseStatus' && sortOrder.order,
      onHeaderCell: (column) => {
        return {
          onClick: () => {
            handleChange(column.sortOrder, column.dataIndex);
          }
        };
      },
    },
    {
      title: 'WTY expiry',
      dataIndex: 'wtyExpiry',
      key: 'wtyExpiry',
      width: '5.5%',
      sorter: (a, b) => moment(a.wtyExpiry, "DD/MM/YYYY") - moment(b.wtyExpiry, "DD/MM/YYYY"),
      sortOrder: sortData?.dataIndex === "wtyExpiry" ? sortData?.order : sortOrder.dataIndex === 'wtyExpiry' && sortOrder.order,
      onHeaderCell: (column) => {
        return {
          onClick: () => {
            handleChange(column.sortOrder, column.dataIndex);
          }
        };
      },
    },
    {
      title: 'Date Lodged',
      dataIndex: 'dateLodged',
      key: 'dateLodged',
      width: '5.5%',
      sorter: (a, b) => moment(a.dateLodged, "DD/MM/YYYY") - moment(b.dateLodged, "DD/MM/YYYY"),
      sortOrder: sortData?.dataIndex === "dateLodged" ? sortData?.order : sortOrder.dataIndex === 'dateLodged' && sortOrder.order,
      onHeaderCell: (column) => {
        return {
          onClick: () => {
            handleChange(column.sortOrder, column.dataIndex);
          }
        };
      },
    },
    {
      title: 'Updated',
      dataIndex: 'updated',
      key: 'updated',
      width: '5.5%',
      sorter: (a, b) => moment(a.updated, "DD/MM/YYYY") - moment(b.updated, "DD/MM/YYYY"),
      sortOrder: sortData?.dataIndex === "updated" ? sortData?.order : sortOrder.dataIndex === 'updated' && sortOrder.order,
      onHeaderCell: (column) => {
        return {
          onClick: () => {
            handleChange(column.sortOrder, column.dataIndex);
          }
        };
      },
    },
    {
      title: 'WTY condition',
      dataIndex: 'wtyCondition',
      key: 'wtyCondition',
      width: '10%',
      sorter: (a, b) => a.wtyCondition?.props?.children?.localeCompare(b.wtyCondition?.props?.children),
      sortOrder: sortData?.dataIndex === "wtyCondition" ? sortData?.order : sortOrder.dataIndex === 'wtyCondition' && sortOrder.order,
      onHeaderCell: (column) => {
        return {
          onClick: () => {
            handleChange(column.sortOrder, column.dataIndex);
          }
        };
      },
    },
  ];

  const submitQueryClick = () => {

    updateLocalStorage();

    let datatosend = {
      modelSeries: modelSeries,
      faultCategory: faultCategory,
      startDate: moment(startDate).format('DD/MM/YYYY'),
      endDate: moment(endDate).format('DD/MM/YYYY'),
      caseStatus: caseStatus
    }
    searchFaultData(datatosend).then((response) => {
      setSource(response.data)
      setclonesource(response.data)
    }).catch(err => console.log(err))
  }
  const showVehicleHistoryModal = () => {
    setVisible(true);
  };

  const handleVehicleHistoryModalClose = () => {
    setVisible(false);
  };

  const onQuerySubmit = ((values) => {
    updateLocalStorage("query");
    const payload =
    {
      fleetNumber: values["FleetNo"],
      bodyNumber: values["BodyNo"],
      chassisNumber: values["ChassisNo"],
      startDate: moment(startDate).format('DD/MM/YYYY'),
      endDate: moment(endDate).format('DD/MM/YYYY'),
      modelSeries: modelSeries,
      faultCategory: faultCategory,
      caseStatus: caseStatus
    }
    searchByVehicle(payload).then(response => {
      setVisible(false);
      setSource(response?.data);
      setclonesource(response.data);
    }).catch(() => {
      message.error(t('message.searchbyvehicleerror'));
    });
  });

  const onClickExportData = (() => {
    dataSource && dataSource.length === 0 ? Modal.info({
      title: '',
      content: (
        <div>
          <p>There is no data to export. Please query data properly and try again.</p>
        </div>
      ),
      onOk() { },
    }) : history.push(AppRoutes.ExportData);
  });

  return (
    <>
      <div>
        <StyledMenu onClick={(e) => handleClick(e.key)} selectedKeys={currentKey} mode="horizontal">
          <Menu.Item key="0" ><Link to={AppRoutes.Home} onClick={resetFilters}>{t('menu.home')}</Link></Menu.Item>
          {!permission?.includes(Modules.RegisterFault) && <Menu.Item key="1"><Link to={AppRoutes.RegisterFault}>{t('menu.registerfault')}</Link></Menu.Item>}
          {!permission?.includes(Modules.ExportData) && <Menu.Item key="2" onClick={() => onClickExportData()}>{t('menu.exportdata')}</Menu.Item>}
          <Menu.Item key="4" onClick={showVehicleHistoryModal}>{t('menu.vehiclehistory')}</Menu.Item>
          {user?.userRole === "ADMIN" && <Menu.Item key="5"><Link to={AppRoutes.Admin}>{t('menu.admin')}</Link></Menu.Item>}
        </StyledMenu>
      </div>
      <div style={{ padding: '1.25rem' }}>
        <Space size={25}>
          <div>
            <label>{t('lblStartDate')}</label><br />
            <StyledDatePicker allowClear={false} disabledDate={d => !d || d.isAfter(endDate)} value={moment(startDate, "DD/MM/YYYY")} format="DD/MM/YYYY" onChange={(val) => setStartDate(moment(val))} />
          </div>
          <div>
            <label>{t('lblEndDate')}</label><br />
            <StyledDatePicker allowClear={false} disabledDate={d => !d || d.isSameOrBefore(startDate)} value={moment(endDate, "DD/MM/YYYY")} format="DD/MM/YYYY" onChange={(val) => setEndDate(moment(val))} />
          </div>
          <div>
            <label>{t('lblModelSeries')}</label><br />
            <StyledSelect
              size='large'
              value={modelSeries}
              onChange={val => setModelSeries(val)}
            >
              {modelSeriesList && modelSeriesList.map((options, index) => {
                return <Option value={options} key={index}>{options}</Option>
              })}
            </StyledSelect>
          </div>
          <div>
            <label>{t('lblFaultType')}</label><br />
            <StyledSelect
              size='large'
              value={faultCategory}
              onChange={val => setFaultCategory(val)}
            >
              {faultList && faultList.map((faultoption, index) => {
                return <Option value={faultoption} key={index}>{faultoption}</Option>
              })}
            </StyledSelect>
          </div>
          <div>
            <label>{t('lblCaseStatus')}</label><br />
            <StyledSelect
              size='large'
              value={caseStatus}
              onChange={val => setcaseStatus(val)}
            >
              {caseStatusList && caseStatusList.map((options, index) => {
                return <Option value={options.caseStatusValue} key={index}>{options.caseStatusLabel}</Option>
              })}
            </StyledSelect>
          </div>
          <div>
            <StyledButton htmlType="submit" onClick={submitQueryClick}>{t('lblsubmitquery')}</StyledButton>
          </div>
        </Space>
      </div>
      <div style={{ paddingTop: '1.25rem' }}>
        <StyledTable
          columns={columns}
          dataSource={dataSource1}
          pagination={false}
          scroll={{ y: 280 }}
          bordered
        />
        <div className="secondtable">
          <StyledTable
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            scroll={{ y: 440 }}
            bordered
            onRow={(i) => ({
              onDoubleClick: (e) => {
                updateLocalStorage('query');
                history.push(AppRoutes.UpdateFaultCase.replace(':id', i.key))
              }
            })}
            footer={currentPageData => {
              sortIds.length = 0;
              if (sortIds.includes(currentPageData.map(item => item.key)) === false) {
                sortIds.push(currentPageData.map(item => item.key));
              }
              updateLocalStorage("sort", sortIds.length > 0 && sortIds);
              return null;
            }}
            rowClassName={(record) => (record.category === "VOR" ? "red" : "")}
          />
        </div>
        <StyledModal
          title={<>
            <Row style={{ justifyContent: "center" }}>
              <StyledImage draggable="false" src={images.logo.default} alt="" />
            </Row>
            <StyledHeader >{t('modalvehiclehistoryheader')}</StyledHeader>
          </>}
          visible={visible}
          onOk={form.submit}
          onCancel={handleVehicleHistoryModalClose}
          closable={false}
          okText="Submit"
          forceRender
          width={400}
          modalRender={modal => (
            <Draggable
              bounds={bounds}
              onStart={(event, uiData) => onStart(event, uiData)}
            >
              <div ref={draggleRef}>{modal}</div>
            </Draggable>
          )}
        >
          <>
            <Form form={form} labelCol={{ span: 12, offset: 1 }} wrapperCol={{ span: 10 }} layout="horizontal" onFinish={onQuerySubmit}>
              <StyledFormItem name="FleetNo" label="Fleet No">
                <Input />
              </StyledFormItem>
              <label style={{ paddingLeft: "10%" }}>
                OR
              </label>
              <StyledFormItem name="BodyNo" label="Body No" >
                <Input />
              </StyledFormItem>
              <label style={{ padding: '5% 0 5% 10%' }}>
                OR
              </label>
              <StyledFormItem name="ChassisNo" label="Chassis No" >
                <Input />
              </StyledFormItem>
            </Form>
          </>
        </StyledModal>
      </div>
    </>
  );
}

export default HomePage;
