import styled from 'styled-components';
import { tokens } from '../../../lib/tokens';

export const StyledContent = styled.div`
    width: ${tokens.sizes.full};   
    overflow: hidden;
`;

export const StyledImage = styled.img`
    float: left;
    height: ${tokens.sizes[106]};
    padding-top: ${tokens.sizes[28]};
`;

export const StyledHeader = styled.h3`
    margin: ${tokens.sizes[41]} 0 0 0; 
`;

export const StyledText = styled.p`
    text-align-last: right;
    padding-right: ${tokens.sizes[35]};
    font-size: ${tokens.sizes[16]};
`;