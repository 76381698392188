import { Menu, Button } from 'antd';
import styled from 'styled-components';
import { tokens } from '../../lib/tokens';

export const StyledMenu = styled(Menu)`
    background-color: ${tokens.palette.grey[3]};
    font-weight: 600;    
`;

export const StyledButton = styled(Button)`
    margin: ${tokens.sizes[10]};
    background-color: ${tokens.palette.grey[3]};
    border: ${tokens.sizes[1]} solid ${tokens.palette.black[0]};
    font-weight: 600;
    margin-top: ${tokens.sizes[70]};
`;

export const Content = styled.div`
    text-align: center;
    padding-top: ${tokens.sizes[70]};
`;