import React, { useContext } from 'react';
import { Switch } from 'react-router-dom';
import { AppRoutes, PageSubHeader } from '../../lib/constants';
import LayoutRoute from '../Layout';
import MainLayout from '../Layout/MainLayout';
import { PageNotFoundPage } from '../PageNotFound';
import HomePage from '../../pages/Home';
import RegisterFault from '../../pages/RegisterFault';
import ExportData from '../../pages/ExportData';
import PrintData from '../../pages/PrintData';
import Admin from '../../pages/Admin';
import ViewFleetVehicles from '../MaintenanceTables/FleetVehicles/ViewFleetVehicles';
import AddFleetVehicles from '../MaintenanceTables/FleetVehicles/AddFleetVehicles';
import ViewDepots from '../MaintenanceTables/Depots/ViewDepots';
import AddDepots from '../MaintenanceTables/Depots/AddDepots';
import AddRepairAgents from '../MaintenanceTables/RepairAgents/AddRepairAgents';
import AddSecondarySubRepairer from '../MaintenanceTables/SecondarySubRepairer/AddSecondarySubRepairer';
import AddApplicationUsers from '../MaintenanceTables/ApplicationUsers/AddApplicationUsers';
import ViewRepairAgents from '../MaintenanceTables/RepairAgents/ViewRepairAgents';
import ViewSecondarySubRepairer from '../MaintenanceTables/SecondarySubRepairer/ViewSecondarySubRepairer';
import ViewApplicationUsers from '../MaintenanceTables/ApplicationUsers/ViewApplicationUsers';
import FunctionGroupAllocation from '../MaintenanceTables/FunctionGroupAllocation';
import FunctionGroups from '../MaintenanceTables/FunctionGroups';
import { Modules } from '../../lib/roles';
import { UserContext } from '../../lib/userContext';

export const Routes = () => {
    const user = useContext(UserContext);
    return (
        <Switch>
            {/* Initial page routes */}
            <LayoutRoute exact path={AppRoutes.Home} layout={MainLayout} pageSubHeader={PageSubHeader.Home} component={HomePage} />
            <LayoutRoute exact path={AppRoutes.RegisterFault} layout={MainLayout} pageSubHeader={PageSubHeader.RegisterFault} component={RegisterFault} pageAccess={Modules.RegisterFault} />
            <LayoutRoute exact path={AppRoutes.ExportData} layout={MainLayout} component={ExportData} pageAccess={Modules.ExportData} />
            <LayoutRoute exact path={AppRoutes.PrintData} layout={MainLayout} component={PrintData} />
            <LayoutRoute exact path={AppRoutes.Admin} layout={(user?.userRole === "ADMIN") && MainLayout} pageSubHeader={PageSubHeader.Admin} component={(user?.userRole === "ADMIN") && Admin} />

            {/* View maintanence data page routes */}
            <LayoutRoute exact path={AppRoutes.FleetVehicles} layout={(user?.userRole === "ADMIN") && MainLayout} pageSubHeader={PageSubHeader.FleetVehicles} component={(user?.userRole === "ADMIN") && ViewFleetVehicles} />
            <LayoutRoute exact path={AppRoutes.Depots} layout={(user?.userRole === "ADMIN") && MainLayout} pageSubHeader={PageSubHeader.Depots} component={(user?.userRole === "ADMIN") && ViewDepots} />
            <LayoutRoute exact path={AppRoutes.RepairAgents} layout={(user?.userRole === "ADMIN") && MainLayout} pageSubHeader={PageSubHeader.RepairAgents} component={(user?.userRole === "ADMIN") && ViewRepairAgents} />
            <LayoutRoute exact path={AppRoutes.SecondarySubRepairer} layout={(user?.userRole === "ADMIN") && MainLayout} pageSubHeader={PageSubHeader.SecondarySubRepairer} component={(user?.userRole === "ADMIN") && ViewSecondarySubRepairer} />
            <LayoutRoute exact path={AppRoutes.ApplicationUsers} layout={(user?.userRole === "ADMIN") && MainLayout} pageSubHeader={PageSubHeader.ApplicationUsers} component={(user?.userRole === "ADMIN") && ViewApplicationUsers} />
            <LayoutRoute exact path={AppRoutes.FunctionGroupAllocation} layout={(user?.userRole === "ADMIN") && MainLayout} pageSubHeader={PageSubHeader.FunctionGroupAllocation} component={(user?.userRole === "ADMIN") && FunctionGroupAllocation} />
            <LayoutRoute exact path={AppRoutes.FunctionGroups} layout={(user?.userRole === "ADMIN") && MainLayout} pageSubHeader={PageSubHeader.FunctionGroups} component={(user?.userRole === "ADMIN") && FunctionGroups} />

            {/* Add maintanence data page routes */}
            <LayoutRoute exact path={AppRoutes.AddFleetVehicles} layout={(user?.userRole === "ADMIN") && MainLayout} pageSubHeader={PageSubHeader.AddFleetVehicles} component={(user?.userRole === "ADMIN") && AddFleetVehicles} />
            <LayoutRoute exact path={AppRoutes.AddDepots} layout={(user?.userRole === "ADMIN") && MainLayout} pageSubHeader={PageSubHeader.AddDepots} component={(user?.userRole === "ADMIN") && AddDepots} />
            <LayoutRoute exact path={AppRoutes.AddRepairAgents} layout={(user?.userRole === "ADMIN") && MainLayout} pageSubHeader={PageSubHeader.AddRepairAgents} component={(user?.userRole === "ADMIN") && AddRepairAgents} />
            <LayoutRoute exact path={AppRoutes.AddSecondarySubRepairer} layout={(user?.userRole === "ADMIN") && MainLayout} pageSubHeader={PageSubHeader.AddSecondarySubRepairer} component={(user?.userRole === "ADMIN") && AddSecondarySubRepairer} />
            <LayoutRoute exact path={AppRoutes.AddApplicationUsers} layout={(user?.userRole === "ADMIN") && MainLayout} pageSubHeader={PageSubHeader.AddApplicationUsers} component={(user?.userRole === "ADMIN") && AddApplicationUsers} />

            {/* Update maintanence data page routes */}
            <LayoutRoute exact path={AppRoutes.UpdateFleetVehicles} layout={(user?.userRole === "ADMIN") && MainLayout} pageSubHeader={PageSubHeader.UpdateFleetVehicles} component={(user?.userRole === "ADMIN") && AddFleetVehicles} />
            <LayoutRoute exact path={AppRoutes.UpdateDepots} layout={(user?.userRole === "ADMIN") && MainLayout} pageSubHeader={PageSubHeader.UpdateDepots} component={(user?.userRole === "ADMIN") && AddDepots} />
            <LayoutRoute exact path={AppRoutes.UpdateRepairAgents} layout={(user?.userRole === "ADMIN") && MainLayout} pageSubHeader={PageSubHeader.UpdateRepairAgents} component={(user?.userRole === "ADMIN") && AddRepairAgents} pageAccess={Modules.ModifyRepairagent} />
            <LayoutRoute exact path={AppRoutes.UpdateSecondarySubRepairer} layout={(user?.userRole === "ADMIN") && MainLayout} pageSubHeader={PageSubHeader.UpdateSecondarySubRepairer} component={(user?.userRole === "ADMIN") && AddSecondarySubRepairer} />
            <LayoutRoute exact path={AppRoutes.UpdateApplicationUser} layout={(user?.userRole === "ADMIN") && MainLayout} pageSubHeader={PageSubHeader.UpdateApplicationUser} component={(user?.userRole === "ADMIN") && AddApplicationUsers} />

            {/* Update fault case page route */}
            <LayoutRoute exact path={AppRoutes.UpdateFaultCase} layout={MainLayout} pageSubHeader={PageSubHeader.UpdateFaultCase} component={RegisterFault} />

            {/* keep least always */}
            <LayoutRoute exact path="*" layout={MainLayout} component={PageNotFoundPage} />
        </Switch>
    )
}
