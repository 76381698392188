import { request, requestObj } from '../lib/request';

const fleetVehiclesApi = request({
  baseURL: process.env.REACT_APP_BASE_API + 'vehicle-info/'
});

export const getFleetVehiclesData = async () => {
  return await fleetVehiclesApi.get('list');
}

export const addNewVehicle = async (payload) => {
  return await fleetVehiclesApi.post('', payload);
}

export const deleteVehicles = async (id) => {
  return await fleetVehiclesApi.delete(`/${id}`);
}

export const getFleetVehicleDataById = async (id) => {
  return await fleetVehiclesApi.get(`/${id}`);
}

export const editVehicle = async (payload) => {
  return await fleetVehiclesApi.put('', payload);
}

export const getFleetVehicleYears = async () => {
  return await fleetVehiclesApi.get('car-years');
}

export const getVehicleModelSeries = async () => {
  return await requestObj.get('model-series/selection-list');
}