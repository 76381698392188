import { request, requestObj } from '../lib/request';

const faultApi = request({
  baseURL: process.env.REACT_APP_BASE_API + 'vehicle-fault/'
});

export const addVehicleFaultData = async (payload) => {
  return await faultApi.post('', payload);
}

export const getVehicleFaultData = async () => {
  return await faultApi.get('list');
}

export const getVehicleCategory = async () => {
  return await requestObj.get('fault-type/selection-list');
}

export const getCaseId = async () => {
  return await requestObj.get('next-case-number');
}

export const searchFaultData = async (payload) => {
  return await requestObj.post('vehicle-fault/search',payload);
}

export const getVehicleFaultById = async (id) => {
  return await faultApi.get(`/${id}`);
}

export const updateVehicleFaultData = async (payload) => {
  return await faultApi.put('', payload);
}

export const cancelFaultCase = async (payload) => {
  return await faultApi.post('cancel-case', payload);
}


export const searchByVehicle = async (payload) => {
  return await faultApi.post('search-by-vehicle', payload);
}

export const getAttachments = async (id) => {
  return await faultApi.get(`attachment/list/${id}`);
}

export const uploadAttachment = data => {
  return faultApi.post(`upload`, data, {
      headers: {
          'Content-Type': 'multipart/form-data',
      },
  });
};

export const addVehicleNote = async (payload) => {
  return await requestObj.post('vehiclenotetextlog', payload);
}

export const getVehicleNotes = async (id) => {
  return await requestObj.get('vehiclenotetextlog/' + id);
}

export const deleteVehicleNote = async (id) => {
  return await requestObj.delete('vehiclenotetextlog/' + id);
}

export const getFileBlob = async (id) => {
  return await requestObj.get('files/' + id,{responseType: 'blob'});
}