import React, { useState, useEffect } from 'react';
import { AppRoutes } from '../../../../lib/constants';
import { StyledMenu, StyledTable } from '../../maintenancetable.style';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getFleetVehiclesData, deleteVehicles } from '../../../../api/fleetVehicles';
import { Space, message, Modal , Button, Tooltip } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

const ViewFleetVehicles = () => {

  const { t } = useTranslation();

  const [source, setSource] = useState([]);

  const { confirm } = Modal;
  const history = useHistory();

  useEffect(() => {
    getFleetVehiclesData().then(response => {
      setSource(response?.data);
    })
      .catch(error => {
        alert(error);
      })
  },[]);  

  const deleteVehiclesData = (id) => {
    if (id) {
      confirm({
        title: t('confirm.deletevehicle'),
        icon: <ExclamationCircleOutlined />,
        onOk() {
          deleteVehicles(id).then((response) => {
            if (response.status === 200) {
              let filteredData = source.filter(data => data.id.toString() !== id.toString());
              setSource(filteredData);
              message.success(t('message.vehicledeletedsuccss'))
            } else {
              message.error(t('message.vehicledeletederror'));
            }
          }).catch(() => {
            message.error(t('message.vehicledeletederror'));
          })
        },
      });
    }
  }

  const columns = [
    {
      title: 'Operator name',
      dataIndex: 'operator',
      key: 'operator',
    },
    {
      title: 'Brand',
      dataIndex: 'brand',
      key: 'brand',
    },
    {
      title: 'Fleet no',
      dataIndex: 'fleetNo',
      key: 'fleetNo',
    },
    {
      title: 'Body no',
      dataIndex: 'bodyNo',
      key: 'bodyNo',
    },
    {
      title: 'Chassis number',
      dataIndex: 'chassisNumber',
      key: 'chassisNumber',
    },
    {
      title: 'Model series',
      dataIndex: 'modelSeries',
      key: 'modelSeries',
    },
    {
      title: 'VIN',
      dataIndex: 'vin',
      key: 'vin',
    },
    {
      title: 'Year',
      dataIndex: 'year',
      key: 'year',
    },
    {
      title: 'WTY ends',
      dataIndex: 'wtyEnds',
      key: 'wtyEnds',
    },
    {
      title: 'WTY condition',
      dataIndex: 'wtyCondition',
      key: 'wtyCondition',
      render: data => (
        <Tooltip placement="topLeft" title={data}>
          {data}
        </Tooltip>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (record) => (
        <>
          <Space size="middle">
            <Button type="link" size="small" onClick={() => history.push(AppRoutes.UpdateFleetVehicles.replace(':id', record.key))} > {t('lbledit')} </Button>
           <Button type="link" size="small" onClick={() => deleteVehiclesData(record.key)}>{t('lbldelete')}</Button>
          </Space>
        </>)
    },
  ];

  const dataSource = source?.map((data) => {
    return {
      key: data?.id.toString(),
      operator: data?.vehicleOperatorName,
      brand: data?.vehicleBrand,
      fleetNo: data?.vehicleFleetNumber,
      bodyNo: data?.vehicleBodyNumber,
      chassisNumber: data?.vehicleChassisNumber,
      modelSeries: data?.vehicleSeries,
      vin: data?.vehicleVin,
      year: data?.vehicleModelYear,
      wtyEnds: moment(data?.vehicleWarrantyEndDate).format("DD/MM/YYYY"),
      wtyCondition: data?.vehicleWarrantyCondition,
    }
  });

  return (
    <>
      <div>
        <StyledMenu mode="horizontal">
          <StyledMenu.Item key={AppRoutes.Home}><Link to={AppRoutes.Home}>{t('menu.home')}</Link></StyledMenu.Item>
          <StyledMenu.Item key={AppRoutes.AddFleetVehicles}><Link to={AppRoutes.AddFleetVehicles}>{t('menu.addvehicle')}</Link></StyledMenu.Item>
        </StyledMenu>
      </div>
      <div style={{ paddingTop: '1.25rem' }}>
        <StyledTable
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          bordered
        />
      </div>
    </>
  );
}

export default ViewFleetVehicles;
