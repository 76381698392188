import React from 'react';
import { images } from '../../assets/image';
import { Header, Image, SubHeader, Content } from './NotAuthorized.styles';
import { useTranslation } from 'react-i18next';

const NotAuthorized = ({ props }) => {

    const { t } = useTranslation();       

    return (
        <>
            <Header>
                <Image src={images.logo.default} alt="" />
                <SubHeader>{t('mainheader')}</SubHeader>
            </Header>
            <Content>{props}</Content>            
        </>
    );
};

export default NotAuthorized;