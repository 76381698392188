import React from 'react';
import { Button, Row, Col, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AppRoutes } from '../../lib/constants';
import { StyledButton, StyledMenu, Content } from './ExportData.styles';

// for blob api calling
import { getFileBlobData } from '../../api/exportData';


const ExportData = () => {

  const { t } = useTranslation();

  const filterSource = localStorage.getItem('currentIdentifiers');
  const exportDataInCsv = () => {
    let payload = [];
    payload = filterSource.split(",");
    payload = payload.map(data => parseInt(data));

      let dataToSend = {};
      dataToSend.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      dataToSend.vehicleFaultIds = payload;

    getFileBlobData(dataToSend).then(blob => {
        var windowUrl = window.URL || window.webkitURL;
        // defined type [ Note : this is for csv please dont change it]
        var contentType = 'text/csv';
        // this will create the Blob object
        var blobdata = new Blob([blob.data],{type:contentType})
        var url = windowUrl.createObjectURL(blobdata);

        // creating and hiting URL
        var anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = 'vehicle_faults.csv';
        anchor.click();
        // for shorthand call url for safe side
        windowUrl.revokeObjectURL(url);

    }).catch(err => {
      message.error(t('downloadfile.error'));
    })

  }

  const resetFilters = () => {
    localStorage.removeItem("filtered");
    localStorage.removeItem("sortOrder");
    localStorage.setItem('currentPage', '0');
    window.location.href = "/home";
  }


  return (
    <>
      <StyledMenu mode="horizontal">
        <StyledMenu.Item key={t('menu.home')} onClick={resetFilters}><Link to={AppRoutes.Home}>{t('menu.home')}</Link></StyledMenu.Item>
      </StyledMenu>
      <Content>
        <Row>
          <Col xs={24} lg={24} md={24}>
            <Button type="link" onClick={exportDataInCsv}>Click here to download the selected completed requests</Button>
          </Col>
        </Row>
        <Row>
          <Col xs={24} lg={24} md={24}>
            <StyledButton htmlType="submit"><Link to={AppRoutes.Home}>{t('lblpreviousscreen')}</Link></StyledButton>
          </Col>
        </Row>
      </Content>
    </>
  );
}

export default ExportData;
