import axios from "axios";
import Cookies from 'js-cookie';

export const request = (config) => {
  const instance = axios.create(config);

  instance.interceptors.request.use(
    config => {
      config.headers.common = {
        "User-Id": Cookies.get("WSG-UID")
      }      
      return config;
    },
    error => {
      alert(error);
      Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    response => {
      const { data } = response;
      if (data?.code && data?.code !== 200) {
        return Promise.reject(new Error(data.message || "Error"));
      } else {
        return response;
      }
    },
    error => {
      console.error(error);
      switch (error?.response?.status) {
        case 403:
          break;
        default:
          break;
      }
      return Promise.reject(error);
    }
  );

  return instance;
}

export const requestObj = request({
  baseURL: process.env.REACT_APP_BASE_API
})