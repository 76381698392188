import React, { useState, useEffect } from "react";
import { AppRoutes } from "../../../lib/constants";
import {
  StyledMenu,
  StyledCheckbox,
  StyledButton,
  SubRepairerHeader,
} from "../maintenancetable.style";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Radio, Space, Row, Col, message, Divider } from "antd";
import {
  getSecondarySubRepairerData,
  getSecondarySubRepairAgentById,
} from "../../../api/secondarySubRepairer";
import { updateFunctionGroupAllocation } from "../../../api/functionGroupAllocation";
import { getFunctionGroupsData } from "../../../api/functionGroups";

const FunctionGroupAllocation = () => {
  const { t } = useTranslation();
  const [secondaryRepairerAgents, setSecondaryRepairerAgents] = useState([]);
  const [selectedsecondaryRepairerAgent, setSelectedSecondaryRepairerAgent] =
    useState();
  const [functionGroupList, setFunctionGroupList] = useState([]);
  const [checkedFunctionGroups, setCheckedFunctionGroups] = useState([]);

  useEffect(() => {
    selectedsecondaryRepairerAgent &&
      getSecondarySubRepairAgentById(selectedsecondaryRepairerAgent).then(
        (response) => {
          const values = response?.data;
          const options = values["functionGroupEntities"].map((row) => {
            return row.id;
          });
          setCheckedFunctionGroups(options);
        }
      );
  }, [selectedsecondaryRepairerAgent]);

  useEffect(() => {
    getSecondarySubRepairerData()
      .then((response) => {
        setSecondaryRepairerAgents(response?.data);
      })
      .catch((error) => {
        alert(error);
      });
    getFunctionGroupsData()
      .then((response) => {
        const options = response?.data.map((row) => {
          return { value: row.id, label: row.name };
        });
        setFunctionGroupList(options);
      })
      .catch((error) => {
        alert(error);
      });
  }, []);

  const onChangeSubRepairAgent = (e) => {
    setSelectedSecondaryRepairerAgent(e.target.value);
  };

  const onFuctionGroupSelectionChange = (e) => {
    setCheckedFunctionGroups(e);
  };

  const onUpdate = () => {
    const payload = checkedFunctionGroups;
    updateFunctionGroupAllocation(selectedsecondaryRepairerAgent, payload)
      .then(() => {
        message.success(t("message.updatefunctiongroupallocationsuccss"));
      })
      .catch(() => {
        message.error(t("message.updatefunctiongroupallocationerror"));
      });
  };

  return (
    <>
      <div>
        <StyledMenu mode="horizontal">
          <StyledMenu.Item key={t("menu.home")}>
            <Link to={AppRoutes.Home}>{t("menu.home")}</Link>
          </StyledMenu.Item>
        </StyledMenu>
      </div>
      <div style={{ padding: "3rem" }}>
        <Row justify="center">
          <Col
            xs={22}
            xl={11}
            sm={11}
            lg={11}
            style={{ padding: "2rem", paddingLeft: "25%" }}
          >
            <SubRepairerHeader>{t("list.secondarysubrepairer")}</SubRepairerHeader>            
            <Radio.Group
              onChange={(e) => onChangeSubRepairAgent(e)}
              value={selectedsecondaryRepairerAgent}
            >
              <Space direction="vertical">
                {secondaryRepairerAgents &&
                  secondaryRepairerAgents.length > 0 &&
                  secondaryRepairerAgents.map((option) => (
                    <Radio value={option.id} key={option.id}>
                      <span style={{ fontSize: "large" }}>{option.name}</span>
                    </Radio>
                  ))}
              </Space>
            </Radio.Group>
          </Col>
          <Divider
            type="verticaL"
            style={{ borderLeft: "1px solid #D4D5DD" }}
          />
          <Col xs={22} xl={11} sm={11} lg={11} style={{ padding: "2rem" }}>
            <h3>{t("list.functiongroups")}</h3>
            <StyledCheckbox
              options={functionGroupList}
              value={checkedFunctionGroups}
              onChange={(e) => onFuctionGroupSelectionChange(e)}
              layout="vertical"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={24} xl={24} sm={24} lg={24}>
            <Space
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: 30,
              }}
            >
              <StyledButton htmlType="submit" onClick={() => onUpdate()}>
                UPDATE
              </StyledButton>
            </Space>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default FunctionGroupAllocation;
