import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    en: {
        translation: {
            "mainheader": 'Vehicle Availability Planner',

            "menu.home": 'HOME',
            "menu.registerfault": "REGISTER FAULT",
            "menu.exportdata": "EXPORT DATA",
            "menu.printdata": "PRINT DATA",
            "menu.vehiclehistory": "VEHICLE HISTORY",
            "menu.admin": "ADMIN",
            "menu.adddepots": "ADD DEPOT",
            "menu.addvehicle": 'ADD VEHICLE',
            "menu.addRepairAgent": 'REGISTER REPAIR AGENT',
            "menu.adduser" : "ADD USER",
            "menu.addSecondarySubRepairer": 'REGISTER SUB REPAIRER/SUPPLIER',
            "menu.editfunctiongroup": 'EDIT FUNCTION GROUP',

            // Home page query labels
            "lblStartDate": 'Query Starting Date',
            "lblEndDate": 'Query Ending Date',
            "lblModelSeries": 'Query Model Series',
            "lblFaultType": 'Query Fault Type',
            "lblCaseStatus": 'Query Case Status',

            // Button labels
            "lblsubmitquery": "Submit query",
            "lbldelete": "Delete",
            "lbledit": "Edit",
            "lblpreviousscreen": "Previous screen",
            "lblsubmit": "Submit",
            "lblsupportingdocupload": "Supporting document uploads",
            "lblupload": "upload doc",
            "lblcancelupload": "cancel upload",
            "lblremovecase": "Cancel case",
            "lblcancel": "Cancel",
            "lblnoteupload": "Update Note",

            // Admin tab options
            "admintab.maintainfleetvh": "Maintenance - Fleet Vehicles",
            "admintab.maintaindepots": "Maintenance - Depots",
            "admintab.maintainrepairagent": 'Maintenance - Repair Agents',
            "admintab.maintainsecondarysubrepairer": 'Maintenance - Secondary Sub Repairer-Supplier',
            "admintab.maintainappusers": 'Maintenance - Application Users',
            "admintab.maintainfunctiongroupallocation": 'Maintenance - Function Group Allocation',
            "admintab.maintainfunctiongroups": 'Maintenance  - Function Groups',

            // Admin tab List headers
            "list.secondarysubrepairer": "Secondary Sub Repairer-Supplier",
            "list.functiongroups": "Function Groups",

            // Vehile History Header
            "modalvehiclehistoryheader": 'VEHICLE HISTORY QUERY SELECTION',

            // Confirm Popups
            "confirm.deletevehicle" : "Do you want to delete this vehicle?",
            "confirm.deletedepot" : "Do you want to delete this depot?",
            "confirm.deleterepairagent" : "Do you want to delete this Repair agent?",
            "confirm.deletesecondarysubrepairer" : "Do you want to delete this Secondary sub repairer?",
            "confirm.deletefunctiongroup" : "Are you sure you want to delete this group?",
            "confirm.deletefault" : "Cancel Case",
            'confirm.deleteuser' : 'Do you want to delete this user?',

            // Message Notification
            "message.depotdeletedsuccss": "depot Has Been Deleted",
            "message.depotdeleteerror": "Unable to Delete Depot..",
            "message.vehicledeletedsuccss": "Vehicle has been deleted",
            "message.vehicledeletederror": "Unable to delete a selected vehicle data",
            "message.repairagentdeletedsuccss": "Repair Agent has been deleted",
            "message.repairagentdeletederror": "Unable to delete a selected Repair Agent",
            "message.secondarysubrepairersuccss": "Secondary Sub Repairer has been deleted",
            "message.secondarysubrepairererror": "Unable to delete a selected Secondary sub repaire",
            "message.addvehiclesuccess": 'Vehicle has been added successfully',
            "message.addvehicleerror": 'Unable to add the Vehicle data',
            "message.editvehiclesuccess": 'Vehicle has been updated successfully',
            "message.editvehicleerror": 'Unable to update the vehicle data',
            "message.editdepotsuccess": 'Depot Updated Successfully',
            "message.editdepoterror": 'Failed To Update Depot',
            "message.depotaddsuccess": "Depot Added Successfully.",
            "message.depotadderror": "Failed To Add Vehicle Depot!",
            "message.addrepairagentsuccess": 'Repair agent has been added successfully',
            "message.addrepairagenterror": 'Unable to add the Repair agent',
            "message.editrepairagentsuccess": 'Repair agent updated successfully',
            "message.editrepairagenterror": 'Unable to update the Repair agent data',
            "message.addusersuccess" : 'User Added Successfully',
            "message.addusererror" : 'Failed To Add User',
            "message.fromishigher" : 'Selected Time is Lower Than From Field',
            "message.addsecondarysubrepairersuccess": 'Secondary Sub Repairer has been added successfully',
            "message.addsecondarysubrepairererror": 'Unable to add the Secondary Sub Repairer data',
            "message.editsecondarysubrepairersuccess": 'Record has been updated successfully',
            "message.editsecondarysubrepairererror": 'Unable to update the record',
            "message.addfaultdetailssuccess": 'Fault has been registered successfully',
            "message.addfaultdetailserror": 'Unable to register the Fault',
            "message.updateusersuccess": 'User Updated Successfully',
            "message.updateusererror" : 'Unable to update user',
            "message.addfunctiongroupsuccess": 'Function group has been added successfully',
            "message.addfunctiongrouperror": 'Unable to add the Function group data',
            "message.editfunctiongroupsuccess": 'Record has been updated successfully',
            "message.editfunctiongrouperror": 'Unable to update the record',
            "message.uniqfunctiongrouperror": 'Function group should be unique',
            "message.deletefunctiongroupsuccss": "Function group has been deleted",
            "message.deletefunctiongriuperror": "Unable to delete a function group",
            "message.updatefunctiongroupallocationsuccss": "Record has been updated successfully",
            "message.updatefunctiongroupallocationerror": "Unable to update the record",
            "message.editfaultsuccess": 'Fault has been updated successfully',
            "message.editfaulterror": 'Unable to update the fault',
            "message.deletefaultsuccess": 'Fault has been deleted successfully',
            "message.deletefaulterror": 'Unable to delete the fault',
            "message.searchbyvehicleerror": 'Unable to search the fault',
            "message.fileuploadsucces": 'File uploaded successfully',
            "message.fileuploaderror": 'Unable to upload file',
            "message.decisionmusterror" : 'You must enter Decision',
            "message.faultdeletedsuccess" : 'Case Cancelled Successfully',
            "message.faultdeleterror" : 'Unable to delete fault',
            "message.userdeleteerror" : 'Unable to delete user',
            "message.userdeletedsuccess" : 'User Deleted Successfully',
            "message.notemusthaveerr" :'Note Must Have Some Text',
            "message.fileselectmust" : 'Need To Select File First',
            "message.filetypeinvalid" : 'File Type Is Not Valid',
            "message.fileistoolarge" : 'Selected File Is Too Large',
            "message.vehiclenotedeleteerror" : 'Unable to delete note',
            "message.vehiclenotedeletedsuccess" : 'Note Deleted Successfully',

            "validation.phoneerror" : "The phone number should start with +61. followed by 9 digital numbers",

            "placeholder.confirmDecision" : 'Enter Y or y For Yes And N or n For No',

            "message.noauthorization": "You are not authorized to access this page. Please log in and try again.",
            "message.nopageauthorize": "You are not authorized to view this page.",

            "downloadfile.error" : "Failed To Download File"
        }
    },
    fr: {
        translation: {

        }
    }
}

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "en",
        fallbackLng: ["en"],
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
