import React, { useState, useEffect } from 'react';
import { AppRoutes } from '../../../../lib/constants';
import { StyledMenu, StyledTable } from '../../maintenancetable.style';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { calculateColumnsWidth } from '../../../../lib/countColumnsWidth';
import { getSecondarySubRepairerData, deleteSecondarySubRepairer } from '../../../../api/secondarySubRepairer';
import { Space, Button, Modal, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const ViewSecondarySubRepairer = () => {

  const { t } = useTranslation();
  const [source, setSource] = useState([]);
  const maxWidthPerCell = 600;
  const tableHeight = 500;

  const { confirm } = Modal;
  const history = useHistory();

  useEffect(() => {
    getSecondarySubRepairerData().then(response => {
      setSource(response?.data);
    })
      .catch(error => {
        alert(error);
      })
  }, []);

  const deleteSecondarySubRepairerData = (id) => {
    if (id) {
      confirm({
        title: t('confirm.deletesecondarysubrepairer'),
        icon: <ExclamationCircleOutlined />,
        onOk() {
          deleteSecondarySubRepairer(id).then((response) => {
            if (response.status === 200) {
              let filteredData = source.filter(data => data.id.toString() !== id.toString());
              setSource(filteredData);
              message.success(t('message.secondarysubrepairersuccss'))
            } else {
              message.error(t('message.secondarysubrepairererror'));
            }
          }).catch(() => {
            message.error(t('message.secondarysubrepairererror'));
          })
        },
      });
    }
  }

  const columns = [
    {
      title: 'Client ID',
      dataIndex: 'clientId',
      key: 'clientId',
      width: '10%'
    },
    {
      title: 'Sub Repair Agent',
      dataIndex: 'subRepairAgent',
      key: 'subRepairAgent',
      width: '13%'
    },
    {
      title: 'Town/City',
      dataIndex: 'town',
      key: 'town',
      width: '15%'
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      width: '14%'
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      key: 'email',
      width: '17%'
    },
    {
      title: 'SMS Phone No',
      dataIndex: 'smsPhoneNo',
      key: 'smsPhoneNo',
      width: '12%'
    },
    {
      title: 'Action',
      key: 'action',
      width: '15%',
      render: (record) => (
        <>
          <Space size="middle">
            <Button type="link" size="small" onClick={() => history.push(AppRoutes.UpdateSecondarySubRepairer.replace(':id', record.key))}>{t('lbledit')}</Button>
            <Button type="link" size="small" onClick={() => deleteSecondarySubRepairerData(record.key)}>{t('lbldelete')}</Button>
          </Space>
        </>)
    },
  ];

  const dataSource = source?.map((data) => {
    return {
      key: data?.id.toString(),
      clientId: data?.clientId,
      subRepairAgent: data?.name,
      town: data?.suburb,
      state: data?.state,
      email: data?.emailAddress,
      smsPhoneNo: data?.smsPhoneNumber,
    }
  });

  const dataTable = calculateColumnsWidth(columns, dataSource, maxWidthPerCell);

  return (
    <>
      <div>
        <StyledMenu mode="horizontal">
          <StyledMenu.Item key={t('menu.home')}><Link to={AppRoutes.Home}>{t('menu.home')}</Link></StyledMenu.Item>
          <StyledMenu.Item key={t('menu.addSecondarySubRepairer')}><Link to={AppRoutes.AddSecondarySubRepairer}>{t('menu.addSecondarySubRepairer')}</Link></StyledMenu.Item>
        </StyledMenu>
      </div>
      <div style={{ paddingTop: '1.25rem' }}>
        <StyledTable
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          scroll={{ x: dataTable.tableWidth, y: tableHeight }}
          bordered
        />
      </div>
    </>
  );
}

export default ViewSecondarySubRepairer;