import { request } from '../lib/request';

const GET_SMS_TIME = 'sms-time/list'
const USER_LIST = 'list';

const usersApi = request({
  baseURL: process.env.REACT_APP_BASE_API + 'user/'
});

export const getUserTypes = async() => {
    return await usersApi.get('user-type/list');
}

export const getSmsTimings = async() => {
    return await usersApi.get(GET_SMS_TIME);
}

export const addUser = async (payload) => {
  return await usersApi.post('',payload)
}

export const getUserDetail = async(payload) => {
  return await usersApi.get(payload)
}

export const allUserList = async () => {
  return await usersApi.get(USER_LIST);
}

export const updateUserDetail = async(payload) => {
  return await usersApi.put('',payload);
}

export const deleteUserDetail = async (id) => {
  return await usersApi.delete(`/${id}`);
}

export const getUserByUserId = async (UserId) => {
  return await usersApi.get('/user-id/' + UserId);
}
