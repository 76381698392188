import React from 'react';
import './ProgressBar.css';

class ProgressBar extends React.Component {
   
    render() {
        return(
            <> 
              <div className="stepbar" >
                <div className={"wrapper " + (this.props.active === 0 ? "active" : "")}>
                    <div className="arrow start"></div> 
                    <div className="middle">
                    <span className="text">Case Created</span>
                    </div>
                    <div className="arrow end"></div>
                </div>
                
                <div className={"wrapper " + (this.props.active === 1 ? "active " : "") + (this.props.isdepotUser ? "greyrald" : "")}>
                    <div className="arrow start"></div> 
                    <div className={"middle"}>
                    <span className="text">Case Accepted</span>
                    </div>
                    <div className="arrow end"></div>
                </div>

                <div className={"wrapper " + (this.props.active === 2 ? "active" : "" + (this.props.isdepotUser ? "greyrald" : ""))}>
                    <div className="arrow start"></div> 
                    <div className="middle">
                    <span className="text">Bus Received</span>
                    </div>
                    <div className="arrow end"></div>
                </div>

                <div className={"wrapper " + (this.props.active === 3 ? "active" : "") }>
                    <div className="arrow start"></div> 
                    <div className="middle">
                    <span className="text">Repair Completed</span>
                    </div>
                    <div className="arrow end"></div>
                </div>
                <div className={"wrapper " + (this.props.active === 4 ? "active" : "") }>
                    <div className="arrow start"></div> 
                    <div className="middle">
                    <span className="text">Receipted by depot</span>
                    </div>
                    <div className="arrow end"></div>
                </div>
            </div>
            </>
        )
    }
}

export default ProgressBar;