import React , { useEffect , useState } from 'react';
import { StyledMenu , StyledUserTable } from '../../maintenancetable.style';
import { AppRoutes } from '../../../../lib/constants';
import { Link , useHistory } from 'react-router-dom';
import { Menu , Space , Button , message , Modal} from 'antd';
import { useTranslation } from 'react-i18next';
import { allUserList , deleteUserDetail } from '../../../../api/users';
import moment from 'moment';

import { ExclamationCircleOutlined } from '@ant-design/icons';

const ViewApplicationUsers = () => {  
  const history = useHistory(); 
  const { t } = useTranslation();
  const { confirm } = Modal;

  const [source, setSource] = useState([]);

  useEffect(() => {
    allUserList().then(response => {
      setSource(response?.data);
    }).catch(error => {
      alert(error);
    })
  }, []);

  const deleteUser = (id) => {
    if(id) {
      confirm({
        title: t('confirm.deleteuser'),
        icon: <ExclamationCircleOutlined />,
        onOk() {
          deleteUserDetail(id).then((response) => {
            if(response.status === 200) {
              let filteredData = source.filter(datas => datas.id.toString() !== id.toString());
              setSource(filteredData);        
              message.success(t('message.userdeletedsuccess'))
            } else {
              message.error(t('message.userdeletedsuccess'));
            }
          }).catch((err) => {
            message.error(t('message.userdeleteerror'));
          })
        },
      });
    }
  }

  const columns = [
    {
      title: 'Client Id',
      dataIndex: 'clientId',
      key: 'clientId',
      width:'5%'
    },
    {
      title: 'User Id',
      dataIndex: 'userId',
      key: 'userId',
      width:'5%'
    },
    {
      title: 'User Name',
      dataIndex:'userName',
      key:'userName',
      width:'5%'
    },
    {
      title: 'Operator Name',
      dataIndex:'operatorName',
      key:'operatorName',
      width:'6%'
    },
    {
      title: 'Depot Name',
      dataIndex:'depotName',
      key:'depotName',
      width:'5%'
    },
    {
      title: 'Repair Agent',
      dataIndex:'supplierRepairAgentName',
      key:'supplierRepairAgentName',
      width:'5%'
    },
    {
      title: 'Security Type',
      dataIndex:'levelType',
      key:'levelType',
      width:'5%'
    },
    {
      title: 'Notify By Email',
      dataIndex:'notifyByEmail',
      key:'notifyByEmail',
      width:'6%'
    },
    {
      title: 'Notify By Sms',
      dataIndex:'notifyBySms',
      key:'notifyBySms',
      width:'5%'
    },
    {
      title: 'Email Address',
      dataIndex:'emailAddress',
      key:'emailAddress',
      width:'7%'
    },
    {
      title: 'SMS phone no.',
      dataIndex:'smsPhoneNumber',
      key:'smsPhoneNumber',
      width:'6%'
    },
    {
      title: 'SMS AVL Hour from',
      dataIndex:'smsAvlHourFrom',
      key:'smsAvlHourFrom',
      width:'7%'
    },
    {
      title: 'SMS AVL Hour to',
      dataIndex:'smsAvlHourTo',
      key:'smsAvlHourTo',
      width:'6%'
    },
    {
      title: 'Date created',
      dataIndex:'createdAt',
      key:'createdAt',
      width:'5%'
    },
    {
      title: 'Action',
      key:'action',
      width:'5%',
      render:(record) => ( 
      <> 
       <Space size="middle">
        <Button type="link" size="small" onClick={() => { history.push(AppRoutes.UpdateApplicationUser.replace(':id', record.key)) } }>{t('lbledit')}</Button>
        <Button type="link" size="small" onClick={() => { deleteUser(record.key) }}>{t('lbldelete')}</Button>
       </Space> 
      </> )
    },

  ];

  const dataSource = source?.map((data) => {
    return {
      key: data?.id.toString(),
      clientId: 'PTAWA', // Todo : need client id from API
      userId: data?.userId,
      userName: data?.userName,
      operatorName: data?.operatorName,
      depotName: data?.depotName,
      supplierRepairAgentName: data?.supplierRepairAgentName,
      levelType: data?.levelType,
      notifyByEmail: data?.notifyByEmail ? 'Yes' : 'No',
      notifyBySms: data?.notifyBySms ? 'Yes' : 'No',
      emailAddress: data?.emailAddress,
      smsPhoneNumber: data?.smsPhoneNumber,
      smsAvlHourFrom: data?.smsAvlHourFrom,
      smsAvlHourTo: data?.smsAvlHourTo,
      createdAt: moment(data?.createdAt).format("DD/MM/YYYY"),
      action: data?.clientId,
    }
  });

  return (
    <>
         <StyledMenu mode="horizontal">
          <Menu.Item key={t('menu.home')}>
            <Link to={AppRoutes.Home}>{t('menu.home')}</Link>
          </Menu.Item>
          <Menu.Item key={t('menu.adduser')}>
            <Link to={AppRoutes.AddApplicationUsers}>{t('menu.adduser')}</Link>
          </Menu.Item>
        </StyledMenu>

        <div style={{ paddingTop: '1.25rem' }}>
        <StyledUserTable
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          bordered
        />
        </div>
        
    </>
  );
}

export default ViewApplicationUsers;
