import { request } from '../lib/request';

const modelseriesApi = request({
  baseURL: process.env.REACT_APP_BASE_API + 'model-series/'
});

export const getModelSeries = async () => {
    return await modelseriesApi.get('search-list');
}
  

