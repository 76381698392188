import styled from 'styled-components';
import { tokens } from '../../lib/tokens';

export const Header = styled.div`
    text-align: center;
`;

export const Image = styled.img`
    height: ${tokens.sizes[180]};
    padding-top: ${tokens.sizes[28]};
`;

export const SubHeader = styled.h3`
    margin: ${tokens.sizes[10]} 0 0 0;
`;

export const Content = styled.h1`
    text-align: center;
    padding: ${tokens.sizes[80]};
`;
