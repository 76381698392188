import React, { useContext } from "react";
import { Route } from "react-router-dom";
import Cookies from 'js-cookie';
import NotAuthorized from "../NotAuthorized";
import { UserContext } from "../../lib/userContext";
import { useTranslation } from "react-i18next";

const LayoutRoute = ({ component: Component, layout: Layout, pageSubHeader: PageSubHeader, pageAccess, ...rest }) => {
    const { t } = useTranslation();
    const user = useContext(UserContext);

    return (
        <Route
            {...rest}
            render={props => (
                <>
                    {!Cookies.get('WSG-UID') || !user?.authenticated ? <NotAuthorized props={t('message.noauthorization')} /> : (pageAccess && (user?.permission?.moduleNoAccess?.includes(pageAccess)))
                        ? <NotAuthorized props={t('message.nopageauthorize')} /> : Layout
                            ? (<Layout props={PageSubHeader ? PageSubHeader : ''}>
                                <Component {...props} />
                            </Layout>)
                            : Component ? <Component {...props} /> : <NotAuthorized props={t('message.nopageauthorize')} />
                    }
                </>
            )}
        />
    )
};

export default LayoutRoute;
